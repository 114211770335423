import React, { useState, useEffect, useRef } from "react";
import { FaClock } from "react-icons/fa";

function QuizTimer({ initialHours = 0, initialMinutes = 0, initialSeconds = 0, updatefTimer }) {
  const [time, setTime] = useState(initialHours * 3600 + initialMinutes * 60 + initialSeconds); // Convert hours, minutes, and seconds to total seconds
  const [isPaused, setIsPaused] = useState(false); // Track if the timer is paused
  const intervalRef = useRef(null); // To store the interval ID

  // Format time (hours, minutes, and seconds)
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Automatically restart the timer when initialHours, initialMinutes, or initialSeconds change
  useEffect(() => {
    setTime(initialHours * 3600 + initialMinutes * 60 + initialSeconds); // Set new time based on input
  }, [initialHours, initialMinutes, initialSeconds]); // Watch for changes in hours/minutes/seconds

  // Start the timer when the component mounts or when reset
  useEffect(() => {
    startTimer(); // Start the timer initially

    return () => clearInterval(intervalRef.current); // Clean up on unmount
  }, []);

  // Update the parent with the current time whenever it changes
  useEffect(() => {
    updatefTimer(formatTime(time)); // Send the formatted time to the parent component
  }, [time, updatefTimer]);

  // Start the timer
  const startTimer = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1); // Increment time by 1 second
      }, 1000);
    }
  };

  // Pause the timer
  const pauseTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the interval to stop the timer
      intervalRef.current = null; // Set to null to prevent multiple intervals
    }
  };

  // Toggle pause and resume
  const togglePause = () => {
    if (isPaused) {
      startTimer(); // Resume the timer
    } else {
      pauseTimer(); // Pause the timer
    }
    setIsPaused((prev) => !prev); // Toggle the paused state
  };

  // Reset the timer (example: go to the home page as per your logic)
  const resetTimer = () => {
    window.location.href = '/'; // Redirect to home page
  };

  return (
    <>
      <FaClock style={{ alignContent:'center', marginRight:'2px',fontSize:'20px'}}/>

      <span style={{ color: "#fff", fontSize: "20px",  }}>
        {formatTime(time)}
      </span>

      <span className="play-stop" style={{ alignContent: 'center' }}>
        {isPaused ? (
          <i
            className="fa fa-play"
            style={{ fontSize: "16px", cursor: "pointer" }}
            onClick={togglePause}
          ></i>
        ) : (
          <i
            className="fa fa-pause"
            style={{ fontSize: "16px", cursor: "pointer" }}
            onClick={togglePause}
          ></i>
        )}
      </span>
    </>
  );
}

export default QuizTimer;
