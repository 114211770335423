import React, { useEffect, useState } from "react";
import {
  AiFillPlayCircle,
  AiOutlinePauseCircle,
  AiOutlineMenu,
} from "react-icons/ai"; // Import Pause icon

import { FaFileAudio, FaRegFileAudio } from "react-icons/fa";

function TextWithIcons(props) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [femaleVoice, setFemaleVoice] = useState(null);

  const [clicked,setClicked]=useState(0)

  useEffect(() => {
    // Fetch the list of available voices and set a female voice
    const voices = window.speechSynthesis.getVoices();
    const femaleVoices = voices.filter((voice) => voice.gender === "female");
    if (femaleVoices.length > 0) {
      setFemaleVoice(femaleVoices[0]);
    }
  }, []);

  const showAlert = () => {
    props.updateType(props.type);
  };

  const speak = () => {
    if (!props.audio) return;
    setIsSpeaking(true);
    const utterance = new SpeechSynthesisUtterance(props.audio);
    if (femaleVoice) {
      utterance.voice = femaleVoice;
    }
    utterance.onend = () => {
      setIsSpeaking(false);
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % props.audio.length);
    };

    window.speechSynthesis.speak(utterance);
  };

  const handlePlay = () => {
    if (!isSpeaking) {
      speak(props.audio[currentTextIndex]);
      showAlert();
      
    } setClicked(2)
  };

  const handlePlay2 = () => {
    if (!isSpeaking) {
      speak(props.audio[currentTextIndex]);
    }
    setClicked(1)
  };

  const handleStop = () => {
    speechSynthesis.cancel(); // Stop the speech
    setIsSpeaking(false); // Update state to stop
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <>
    {props.textshow && (
      <div style={{ flexGrow: 1, marginLeft: "5px", marginRight: "5px" }}>
        {props.text}
      </div>
    )}
  </>
    <>
      {props.textenable && (
        <div style={{ width: "24px", height: "24px" }}>
          <AiOutlineMenu onClick={showAlert} />
        </div>
      )}
  
      {props.audioenable && (
        <div style={{ width: "24px", height: "24px" }}>
          {isSpeaking && clicked === 1 ? (
            <AiOutlinePauseCircle onClick={handleStop} /> // Show Pause when speaking
          ) : (
            <AiFillPlayCircle onClick={handlePlay2} /> // Show Play when not speaking
          )}
        </div>
      )}
  
      <div style={{ width: "24px", height: "24px" }}>
        {isSpeaking && clicked === 2 ? (
          <FaRegFileAudio onClick={handleStop} /> // Show Pause when speaking
        ) : (
          <FaFileAudio onClick={handlePlay} /> // Show Play when not speaking
        )}
      </div>
    </>
  </div>
  
  );
}

export default TextWithIcons;
