import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePreference } from "../context/UserContext";
import Swal from "sweetalert2"; // SweetAlert2 for notifications

function AdminFeedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState("");
  const [replyText, setReplyText] = useState(""); // To store the reply
  const [replyError, setReplyError] = useState(""); // To store error for empty reply
  const [showReplyModal, setShowReplyModal] = useState(false); // To show reply modal
  const [selectedFeedback, setSelectedFeedback] = useState(null); // Track selected feedback
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sorting order

  const username = localStorage.getItem("username");
  const { updateIsNavVisible } = usePreference();

  useEffect(() => {
    updateIsNavVisible(true);
    if (validateDates()) {
      fetchFeedbacks();
    }
  }, [startDate, endDate]);

  const validateDates = () => {
    if (startDate > endDate) {
      setError("Start date cannot be later than end date.");
      return false;
    }
    setError("");
    return true;
  };

  const fetchFeedbacks = () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    let config = {
      method: "post",
      url: `https://s3napi.s3nsoftware.com/api/UserMaster/GetAllCustomerFeedbacks?startDate=${formattedStartDate}&endDate=${formattedEndDate}&userName=${username}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setFeedbacks(response.data);
        console.log(":::::  ",config.url)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to handle reply button click
  const handleReplyClick = (feedback) => {
    setSelectedFeedback(feedback); // Store the selected feedback
    setShowReplyModal(true); // Show the reply modal
    setReplyError(""); // Reset error when opening the modal
    setReplyText(""); // Reset any previously typed reply text
  };

  // Function to handle sending the reply
  const handleSendReply = async () => {
    if (!replyText.trim()) {
      setReplyError("Reply cannot be empty."); // Validate for empty reply
      return;
    }

    const replyData = {
      Feedback: replyText,
      Username: selectedFeedback.Username,
      Id: selectedFeedback.Id,
      Email: selectedFeedback.UserEmailID,
    };

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/UserMasters/ReplyFeedback",
        replyData
      );

      if (response.status === 200) {
        const updatedFeedbacks = feedbacks.map((feedback) => {
          if (feedback.id === selectedFeedback.id) {
            return { ...feedback, Answered: true };
          }
          return feedback;
        });

        setFeedbacks(updatedFeedbacks);
        setShowReplyModal(false);
        setReplyText(""); // Reset reply text after sending
        Swal.fire("Success", "Reply sent successfully!", "success");
      }
    } catch (error) {
      console.log(replyData);
      console.error("Error sending reply:", error);
      Swal.fire("Error", "There was an issue sending the reply.", "error");
    }
  };

  const sortByUsername = () => {
    const sortedFeedbacks = [...feedbacks].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.Username.toLowerCase() > b.Username.toLowerCase() ? 1 : -1;
      } else {
        return a.Username.toLowerCase() < b.Username.toLowerCase() ? 1 : -1;
      }
    });

    setFeedbacks(sortedFeedbacks);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sort order
  };

  return (
    <div className="container mt-5" style={{ height: "90vh", display: "flex", flexDirection: "column" }}>
      <h2 className="mb-4">Customer Feedbacks</h2>

      <div className="row mb-4">
        <div className="col">
          <label>Start Date:</label>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yyyy-MM-dd" className="form-control" />
        </div>
        <div className="col">
          <label>End Date:</label>
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yyyy-MM-dd" className="form-control" />
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <div style={{ flexGrow: 1, overflowY: "auto" }}>
        <table className="table table-striped table-bordered" style={{ tableLayout: "fixed", borderRadius: "8px", overflow: "hidden" }}>
          <thead className="thead-dark" style={{ backgroundColor: "#0077b6", color: "#fff" }}>
            <tr>
              <th style={{ width: "5%" }}>Sno</th>
              <th style={{ width: "10%" }}>Id</th>
              <th style={{ width: "15%", cursor: "pointer" }} onClick={sortByUsername}>
                Username
                {sortOrder === "asc" ? (
                  <span style={{ color: "blue", marginLeft: "5px", fontSize: "30px" }}>&#8593;</span>
                ) : (
                  <span style={{ color: "green", marginLeft: "5px", fontSize: "30px" }}>&#8595;</span>
                )}
              </th>
              <th style={{ width: "20%", wordWrap: "break-word", whiteSpace: "normal" }}>User Email ID</th>
              <th style={{ width: "20%", wordWrap: "break-word", whiteSpace: "normal" }}>Comment</th>
              <th style={{ width: "15%" }}>Subject</th>
              <th style={{ width: "10%" }}>First Name</th>
              <th style={{ width: "15%" }}>Mobile Number</th>
              <th               style={{ width: "15%" }}>Updated On</th>
              <th style={{ width: "10%" }}>Answered On</th>
              <th style={{ width: "10%" }}>Answered</th>
              <th style={{ width: "10%" }}>Comment Status</th>
              <th style={{ width: "10%" }}>Status</th> {/* Reply column */}
            </tr>
          </thead>
          <tbody>
            {feedbacks
              .filter((feedback) => !feedback.Answered) // Hide answered feedbacks
              .map((feedback, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#caf0f8" : "#ade8f4", // Alternating row colors
                    color: "#03045e", // Dark text color
                  }}
                  className="table-row"
                >
                  <td>{index + 1}</td>
                  <td style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    {feedback.Id}
                  </td>
                  <td>{feedback.Username}</td>
                  <td style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    {feedback.UserEmailID}
                  </td>
                  <td style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    {feedback.Comment}
                  </td>
                  <td style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    {feedback.Subject}
                  </td>
                  <td>{feedback.FirstName}</td>
                  <td>{feedback.MobileNumber}</td>
                  <td>{new Date(feedback.UpdatedOn).toLocaleString()}</td>
                  <td>
                    {feedback.AnsweredOn
                      ? new Date(feedback.AnsweredOn).toLocaleString()
                      : "N/A"}
                  </td>
                  <td>{feedback.Answered ? "Yes" : "No"}</td>
                  <td>{feedback.CommentStatus ? "Active" : "Inactive"}</td>
                  <td>
                    {/* Reply button */}
                    <button
                      className="btn btn-primary btn-sm"
                      style={{
                        backgroundColor: "#023e8a",
                        borderColor: "#023e8a",
                        color: "#fff",
                      }} // Blue button
                      onClick={() => handleReplyClick(feedback)}
                    >
                      Reply
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Modal for replying to feedback */}
      {showReplyModal && (
        <>
          {/* Modal Backdrop */}
          <div className="modal-backdrop fade show"></div>

          {/* Modal */}
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog"
              role="document"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh", // Full viewport height
              }}
            >
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: "#caf0f8", color: "#0077b6" }}
                >
                  <h5 className="modal-title">Reply to Feedback</h5>
                  <button
                    type="button"
                    className="close btn"
                    onClick={() => setShowReplyModal(false)}
                    style={{ position: "absolute", right: "15px", top: "15px" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    <strong>Subject:</strong> {selectedFeedback?.Subject}
                  </p>
                  <p>
                    <strong>Comment:</strong> {selectedFeedback?.Comment}
                  </p>
                  <textarea
                    className="form-control"
                    placeholder="Type your reply here..."
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    style={{
                      background: "linear-gradient(135deg, #e0f7fa, #80deea)", // Gradient background
                      border: "2px solid #0077b6", // Blue border
                      color: "#0077b6", // Text color
                      boxShadow: "0px 4px 8px rgba(0, 123, 255, 0.5)", // Shadow for effect
                      minHeight: "100px", // Make it bigger
                      marginBottom: "10px",
                    }}
                  />
                  {replyError && (
                    <div className="alert alert-danger">{replyError}</div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowReplyModal(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSendReply}
                  >
                    Send Reply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const formatDate = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

export default AdminFeedback;

