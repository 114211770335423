import React, { useState, useEffect, useRef } from 'react';
// Optional: Import icons if you choose to enhance with icons
// import { FaBook, FaPencilAlt, FaList, FaRandom } from 'react-icons/fa';

const ComingSoon = () => {
  const features = {
    "Coming Soon": "Vocabulary quizzes, Search a word enhancement, Roots, etc.",
    "Spelling 101!!!": "Guaranteed to improve School Spelling skills and give a jumpstart for advancing in contests!",
    "ALWAYS FREE LISTS": "(Free sign up required for tracking progress) - Fry and Dolch sight words, SAT words, 1000+ High quality Spelling bee words - 5 difficulty categories",
    "Random Word Packs": "Random word packs 1 & 2 - High quality spelling bee words for beginner spellers",
  };

  const featureKeys = Object.keys(features);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const intervalRef = useRef(null);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === featureKeys.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change feature every 5 seconds
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    startInterval();
    return () => stopInterval();
  }, [featureKeys.length]);

  useEffect(() => {
    // Trigger fade-in animation
    setIsAnimating(true);

    // Remove the animation class after animation completes
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 1000); // Duration matches the fadeIn animation duration

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const currentKey = featureKeys[currentIndex];
  const currentValue = features[currentKey];

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'linear-gradient(135deg, #afa494, #afa494)',
    padding: '5px',
    borderRadius: '15px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
    marginBottom: '10px',
    border: '2px solid #ffffff',
    animation: 'bounce 2s infinite',
    flexWrap: 'wrap',
    transition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    // Removed fixed height to handle via CSS media queries
  };

  const descriptionStyle = {
    fontSize: '1.2rem',
    color: '#ffffff',
    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)',
  };

  return (
    <>
      <style>
        {`
          /* Keyframe Animations */
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }

          @keyframes glow {
            0% {
              text-shadow: 0 0 5px #ffffff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff;
            }
            100% {
              text-shadow: 0 0 10px #ffffff, 0 0 20px #ff00ff, 0 0 30px #ff00ff, 0 0 40px #ff00ff;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }

          /* Combined Animations for Heading */
          .heading-cs {
            font-size: 1rem;
            font-weight: bold;
            color: #ffffff;
            text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
            animation: glow 1.5s infinite alternate, blink 1s infinite;
            margin-bottom: 10px;
          }

          /* Extend Animations When Fade-In is Active */
          .heading-cs.fade-in {
            animation: glow 1.5s infinite alternate, blink 1s infinite, fadeIn 1s forwards;
          }

          /* Media Queries for all screen sizes */

          /* Large screens */
          @media (min-width: 1200px) {
            .heading-cs {
              font-size: 1.7rem;
            }
            .description-cs {
              font-size: 1.5rem;
            }
            .container-cs {
              height: 150px; /* Adjusted height for larger screens */
            }
          }

          /* Medium screens (tablets, small desktops) */
          @media (min-width: 768px) and (max-width: 1199px) {
            .heading-cs {
              font-size: 1.5rem;
            }
            .description-cs {
              font-size: 1.3rem;
            }
            .container-cs {
              height: 150px; /* Adjusted height for medium screens */
            }
          }

          /* Small screens (large phones, small tablets) */
          @media (min-width: 576px) and (max-width: 767px) {
            .container-cs {
              flex-direction: column;
              height: 200px; /* Increased height for smaller screens */
              padding: 10px;
            }

            .heading-cs {
              font-size: 1.3rem;
            }

            .description-cs {
              font-size: 1.2rem;
            }
          }

          /* Extra small screens (phones) */
          @media (max-width: 575px) {
            .container-cs {
              flex-direction: column;
              padding: 10px;
              height: 200px; /* Increased height for extra small screens */
            }

            .heading-cs {
              font-size: 1.2rem;
              text-align: center;
            }

            .description-cs {
              font-size: 1rem;
              text-align: left;
              padding-left: 0;
            }
          }

          /* Optional: Reduce motion for users who prefer it */
          @media (prefers-reduced-motion: reduce) {
            .heading-cs {
              animation: none;
            }
            .container-cs {
              animation: none;
            }
          }
        `}
      </style>

      <div
        style={containerStyle}
        className="container-cs"
        onMouseEnter={stopInterval}
        onMouseLeave={startInterval}
        role="region"
        aria-label="Coming Soon Features"
      >
        {/* Feature Heading and Description */}
        <div>
          <p
            className={`heading-cs ${isAnimating ? 'fade-in' : ''}`}
          >
            {currentKey}
          </p>
          <p className="description-cs" style={descriptionStyle}>
            {currentValue}
          </p>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
