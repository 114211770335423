import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Add plugin for labels

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend, ChartDataLabels);

const UserRankings = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});

  // Fetch user rankings data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://s3napi.s3nsoftware.com/api/UserMaster/UsersRankings"
        );
        setUsers(response.data);
        setLoading(false);
        updateOverallChartData(response.data); // Initial overall report for all users
      } catch (error) {
        setError("Error fetching Data");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Helper function to update chart data for a specific user
  const updateChartData = (user) => {
    const data = {
      labels: [
        "Total Quizzes",
        "Completed Quizzes",
        "Correct Answers",
        "Wrong Answers",
        "AvgCmptd",
        "AvgAtmpt",
        "AvgInc",
        "Rank",
      ],
      datasets: [
        {
          data: [
            user.TotalQuizzes,
            user.CompletedQuizzes,
            user.TotalCorrectAnswers,
            user.TotalWrongAnswers,
            user.AvgCompletedPercentage,
            user.AvgAttemptedPercentage,
            user.AvgIncompletePercentage,
            user.Rank,
          ],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#E77B7E",
            "#4BC0C0",
            "#FFCE56",
            "#BC9548",
            "#808080",
            "#C4E7FF",
          ],
          borderColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#E77B7E",
            "#BC9548",
            "#808080",
            "#C4E7FF",
          ],
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
  };

  // Helper function to update chart data for overall report (aggregated data)
  const updateOverallChartData = (users) => {
    const totalQuizzes = users.reduce((acc, user) => acc + user.TotalQuizzes, 0);
    const completedQuizzes = users.reduce((acc, user) => acc + user.CompletedQuizzes, 0);
    const correctAnswers = users.reduce((acc, user) => acc + user.TotalCorrectAnswers, 0);
    const wrongAnswers = users.reduce((acc, user) => acc + user.TotalWrongAnswers, 0);
    const avgCmptd = (users.reduce((acc, user) => acc + user.AvgCompletedPercentage, 0) / users.length).toFixed(2);
    const avgAtmpt = (users.reduce((acc, user) => acc + user.AvgAttemptedPercentage, 0) / users.length).toFixed(2);
    const avgInc = (users.reduce((acc, user) => acc + user.AvgIncompletePercentage, 0) / users.length).toFixed(2);
    const avgRank = (users.reduce((acc, user) => acc + user.Rank, 0) / users.length).toFixed(0);

    const data = {
      labels: [
        "Total Quizzes",
        "Completed Quizzes",
        "Correct Answers",
        "Wrong Answers",
        "AvgCmptd",
        "AvgAtmpt",
        "AvgInc",
        "Avg Rank",
      ],
      datasets: [
        {
          data: [
            totalQuizzes,
            completedQuizzes,
            correctAnswers,
            wrongAnswers,
            avgCmptd,
            avgAtmpt,
            avgInc,
            avgRank,
          ],
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#36A2EB",
            "#E77B7E",
            "#4BC0C0",
            "#BC9548",
            "#808080",
            "#C4E7FF",
          ],
          borderColor: [
            "#FF6384",
            "#E77B7E",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#BC9548",
            "#808080",
            "#C4E7FF",
          ],
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
  };

  // Handle selecting a specific user for the chart
  const handleUserClick = (user) => {
    setSelectedUser(user);
    updateChartData(user);
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div
      className="container-fluid mt-5"
      style={{ backgroundColor: "#2E3B4E", color: "white" }}
    >
      <div className="row">
        {/* Rankings Table Section */}
        <div
          className="col-md-7"
          style={{
            overflowY: "auto",
            maxHeight: "90vh",
            display: "grid",
            gap: "20px",
          }}
        >
          <div>
            <center>
              <h2>User Rankings</h2>
            </center>
          </div>

          <table
            className="table table-bordered table-striped"
            style={{ color: "white" }}
          >
            <thead>
              <tr>
                <th>Sno</th>
                <th>UserId</th>
                <th>TotQz</th>
                <th>CmtQz</th>
                <th>TCA</th>
                <th>TWA</th>
                <th>AvgCmptd%</th>
                <th>AvgAtmpt%</th>
                <th>AvgInc%</th>
                <th>Rank</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={index}
                  onClick={() => handleUserClick(user)} // Set selected user on click
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td>{user.UserId}</td>
                  <td>{user.TotalQuizzes}</td>
                  <td>{user.CompletedQuizzes}</td>
                  <td>{user.TotalCorrectAnswers}</td>
                  <td>{user.TotalWrongAnswers}</td>
                  <td>{user.AvgCompletedPercentage.toFixed(2)}</td>
                  <td>{user.AvgAttemptedPercentage.toFixed(2)}</td>
                  <td>{user.AvgIncompletePercentage.toFixed(2)}</td>
                  <td>{user.Rank}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Donut Chart Section */}
        <div
          className="col-md-5"
          style={{
            overflowY: "auto",
            maxHeight: "90vh",
            display: "grid",
          }}
        >
          <center>
            <h3>
              {selectedUser
                ? `Chart for User: ${selectedUser.UserId}`
                : "Overall User Rankings Chart"}
            </h3>
          </center>
          <Container>
            <Doughnut
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  datalabels: {
                    color: "white",
                    formatter: (value, ctx) => {
                      let total = ctx.dataset.data.reduce((a, b) => a + b, 0);
                      let percentage = ((value / total) * 100).toFixed(2) + "%";
                      return percentage;
                    },
                    font: {
                      size: 14,
                    },
                  },
                  legend: {
                    position: "left",
                    labels: {
                      color: "white",
                      boxWidth: 20,
                    },
                  },
                  title: {
                    display: true,
                    color: "white",
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        return tooltipItem.label + ": " + tooltipItem.raw;
                      },
                    },
                  },
                },
                hover: {
                  mode: "nearest",
                  intersect: true,
                },
              }}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default UserRankings;
