import React, { useEffect, useState } from "react";
import styles from "./QuizzesModel.module.css"; // Define modal styles in this CSS file
import axios from "axios";

import imagePath from "../assets/img/john-schnobrich-FlPc9_VocJ4-unsplash.jpg";

import { Button, Modal } from "react-bootstrap";

function QuizzesModel(props) {
  const [quizzes, setQuizzes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const selectHandle = (qid) => {
    props.updateQuizId(qid);
    closeModal();
  };

  const closeModal = () => {
    props.updateQuizModel(false);
  };

  useEffect(() => {
    const appToken = localStorage.getItem("webtoken");
    const username = localStorage.getItem("username");
    const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetSubCategories?catid=${props.catId}&username=${username}`;
    const auth = `Bearer ${appToken}`;

    const getAllQuizzesEx = async (iteration = 1) => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: URL,
          headers: {
            Authorization: auth,
          },
        };

        const response = await axios.request(config);
        setQuizzes(response.data);
        setLoading(false);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403) &&
          iteration === 1
        ) {
          await updateToken();
          getAllQuizzesEx(2);
        } else {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    getAllQuizzesEx();
  }, [props.catId]);

  const updateToken = async () => {
    // Implement your logic to update the token here
  };

  // Generate a light random color
  const generateLightColor = () => {
    const letters = "CDEF"; // Using only lighter hex digits
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  // Generate a dark random color for outline and text
  const generateDarkColor = () => {
    const letters = "012345"; // Using only darker hex digits
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const calculatePercentage = (progress) => {
    const values = progress.split(",");
    const total = parseInt(values[1], 10); // second value is the total (6)
    const achieved = parseInt(values[2], 10); // third value is the achieved (1)

    if (total > 0) {
      const percentage = (achieved / total) * 100;
      return parseFloat(percentage.toFixed(2)); // Return the percentage rounded to 2 decimal places
    } else {
      return 0; // Return 0 if total is 0 or less
    }
  };

  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <div style={{ alignContent: "center", marginBottom: "10px" }}>
              <span className={styles.close} onClick={closeModal}>
                &times;
              </span>

              <label
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "28",
                }}
              >
                {props.pack.CategoryName}
                {props.pack.Progress ? (
                  <>({props.pack.Progress.split(",")[0]}W)</>
                ) : (
                  "(0W)"
                )}
              </label>
            </div>

            <div>
              <div className={styles.container}>
                {quizzes.map((subcategory, index) => (
                  <div key={index} className={styles.subcategoryContainer}>
                    <div className="col-md-12" key={index}>
                      {subcategory.Quizzes.length > 3 ? (
                        // Render as previously, if the number of quizzes is greater than 3
                        <div>
                          <div
                            className="first-last"
                            style={{
                              display: "inline-block",
                              padding: "5px 10px",
                              borderRadius: "10px",
                              backgroundColor: "#f3f4f6",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              fontFamily:
                                "'Comic Sans MS', cursive, sans-serif",
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#333",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              marginBottom: "5px",
                            }}
                          >
                            {subcategory.SubCatName}
                            {subcategory.WordCount ? (
                              <span style={{ color: "#777", fontSize: "14px" }}>
                                ({subcategory.WordCount}W)
                              </span>
                            ) : (
                              <span style={{ color: "#777", fontSize: "14px" }}>
                                (0W)
                              </span>
                            )}
                          </div>
                          <div className={styles.circlesContainer}>
                            {subcategory.Quizzes.map((item, index) => {
                              const bgColor = generateLightColor();
                              const outlineColor = generateDarkColor();
                              const textColor = outlineColor;

                              return (
                                <div
                                  title={item.QuizName}
                                  className={styles.circle}
                                  key={index}
                                  onClick={() => selectHandle(item.Id)}
                                  style={{
                                    backgroundColor: bgColor,
                                    color: textColor,
                                    border: `3px solid ${outlineColor}`,
                                    display: "inline-block",
                                    margin: "2px",
                                    alignContent: "center",
                                    textAlign:'center',
                                  }}
                                >
                                  <p>{item.QuizNumber}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) :  (
                        // Render as table when there are exactly 3 quizzes
                        <div>
                        <table className={styles.quizTable} style={{ borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td style={{ border: "none" }}>
                                <div
                                  className="first-last"
                                  style={{
                                    padding: "5px 10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#f3f4f6",
                                    fontFamily: "'Comic Sans MS', cursive, sans-serif",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "#333",
                                    textAlign: "center",
                                  }}
                                >
                                  {subcategory.SubCatName}
                                  {subcategory.WordCount ? (
                                    <span style={{ color: "#777", fontSize: "14px" }}>
                                      ({subcategory.WordCount}W)
                                    </span>
                                  ) : (
                                    <span style={{ color: "#777", fontSize: "14px" }}>(0W)</span>
                                  )}
                                </div>
                              </td>
                              <td style={{ border: "none" }}>
                                <div className={styles.circlesContainer}>
                                  {subcategory.Quizzes.map((item, index) => {
                                    const bgColor = generateLightColor();
                                    const outlineColor = generateDarkColor();
                                    const textColor = outlineColor;
                  
                                    return (
                                      <div
                                        title={item.QuizName}
                                        className={styles.circle}
                                        key={index}
                                        onClick={() => selectHandle(item.Id)}
                                        style={{
                                          backgroundColor: bgColor,
                                          color: textColor,
                                          border: `3px solid ${outlineColor}`,
                                          display: "inline-block",
                                          margin: "2px",
                                          alignContent: "center",
                                          textAlign:'center',
                                        }}
                                      >
                                        <p>{item.QuizNumber}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizzesModel;
