import React from "react";

const SpellingLoader2 = () => {
  const letters = ["S", "P", "E", "L", "L", "I", "N", "G"]; // The letters for the loader

  // Function to generate random light colors (avoiding dark colors)
  const getRandomLightColor = () => {
    const letters = "89ABCDEF"; // Restrict to higher values to get lighter colors
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.iconContainer}>
        {letters.map((letter, index) => (
          <span
            key={index}
            style={{
              ...styles.icon,
              backgroundColor: getRandomLightColor(), // Assign random light background
              animationDelay: `${index * 0.2}s`,
            }}
          >
            <span style={styles.letter}>{letter}</span>
          </span>
        ))}
      </div>

      <style>
        {`
          @keyframes fadeZoom {
            0% {
              opacity: 0;
              transform: scale(1);
            }
            50% {
              opacity: 1;
              transform: scale(1.5);
            }
            100% {
              opacity: 0;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed", // Full screen overlay
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark semi-transparent background
    zIndex: 9999, // Ensure it appears above all elements
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Center the loader
  },
  iconContainer: {
    display: "flex", // Align letters horizontally
    fontSize: "20px", // Clip-art style font size
    letterSpacing: "5px", // Add space between letters
  },
  icon: {
    display: "inline-block",
    margin: "0px", // Space between letters
    padding: "10px", // Padding for the "clip art" effect
    borderRadius: "10px", // Rounded borders to mimic clip art feel
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.3)", // Shadow to create depth
    animation: "fadeZoom 1.5s infinite ease-in-out", // Zoom and fade effect
    border: "2px solid #fff", // Bold white border for the clip art effect
  },
  letter: {
    display: "inline-block",
    fontSize: "20px", // Size of the letter
    fontWeight: "bold", // Bold letters
    color: "#fff", // White text color inside
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Text shadow for depth
    fontFamily: "'Comic Sans MS', cursive, sans-serif", // Clip-art style font
  },
};

export default SpellingLoader2;
