import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import styles from "./FlyAnimationComponent.module.css";

function FlyingEffectComponent(props) {
  const [explode, setExplode] = useState(props.cheers);

  useEffect(() => {
    setTimeout(() => {
      setExplode(false);
      props.updateCheers(false);
    }, 5000);
  }, [props.cheers]);

  return <div>{explode && <Confetti />}</div>;
}

export default FlyingEffectComponent;
