import React, { useEffect, useState } from "react";
import axios from "axios";
import { usePreference } from "../context/UserContext";
import styles from "./WordModel.module.css";

const CommentModel = () => {
  const username = localStorage.getItem("username");
  const appToken = localStorage.getItem("webtoken");
  const auth = "Bearer " + appToken;

  const {
    comment,
    updateComment,
    isCommentOpen,
    updateCommetOpen,
  } = usePreference();

  const [localComment, setLocalComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setLocalComment(comment.Comment);
  }, [comment]);

  const closeModal = () => {
    updateCommetOpen(false);
    setIsSuccess(false); // Reset success state on close
  };

  const saveUserComments = async () => {
    setIsLoading(true); // Show loader when saving starts
    const userComments = {
      Username: username || "fixedUsername",
      CatId: 1,
      Subcat: 2,
      QuizId: 3,
      Question: 4,
      FirstInserted: new Date(),
      Comment: localComment, // Use localComment for saving
      WordId: 123,
    };
    comment.Comment = localComment;
    comment.FirstInserted = new Date();

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/CustomerFeedbacks/SaveUserCommets",
        comment
      );
      if (response.status === 200) {
        setIsSuccess(true); // Show success view
        setLocalComment(""); // Clear the input field after successful submission
      } else {
        alert("Failed to save comment with status: " + response.status);
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      alert("Error saving comment: " + error.message);
    } finally {
      setIsLoading(false); // Hide loader once request is completed
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUserComments();
  };

  return (
    <div className={styles.modal_container}>
      {isCommentOpen && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              {isLoading ? (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <p>Loading...</p> {/* Loader can be styled or replaced with a spinner */}
                </div>
              ) : isSuccess ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    backgroundColor: "#d4edda", // Light green background
                    color: "#155724", // Dark green text
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h3>
                    <span style={{ fontSize: "2rem", marginRight: "10px" }}>
                      ✓
                    </span>
                    Word Feedback saved successfully!
                  </h3>
                  <button
                    onClick={closeModal}
                    style={{
                      marginTop: "20px",
                      padding: "10px 20px",
                      backgroundColor: "#28a745", // Green button background
                      color: "white", // Button text color
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <textarea
                      id="comment"
                      placeholder="Enter your word feedback here..."
                      required
                      value={localComment}
                      onChange={(e) => setLocalComment(e.target.value)}
                      rows="4"
                      cols="40"
                      style={{ marginBottom: "20px", textAlign: "center" }}
                    />
                    <button
                      type="submit"
                      style={{
                        textAlign: "center",
                        padding: "10px 20px",
                        backgroundColor: "#007bff", // Blue button background
                        color: "white", // Button text color
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentModel;
