import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import Question from "./Question";
import InputWithButton from "./InputWithButton";
import QA from "./QA";
import FlyingEffectComponent from "./FlyingAnimationComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import Swal from "sweetalert2";
import MultipleChoice from "../../components/newplayer/MultipleChoice";
import QuizTimer from "./QuizTimer";
import { useNavigate } from "react-router-dom";

const PlayGround = (props) => {
  const appToken = localStorage.getItem("webtoken");
  const username = localStorage.getItem("username");
  const auth = "Bearer " + appToken;
  const [replay, setReplay] = useState(null);

  const navigate = useNavigate();

  const ShowOpenNextQuiz = async (username) => {
    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/QuizDatas/ShowOpenNextQuiz",
        null, // No data to be sent in the body
        {
          params: {
            Id: props.quizId,
            firstFind: true,
            username: username,
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      return response.data.toString();
    } catch (error) {
      console.error("There was an error making the request:", error);
    }
  };
  useEffect(() => {
    console.log("useeffect 1");

    let _index = 0;
    let currentQn = {};
    let currentIx = 0;
    let qns = [];

    if (props.quizId != 0) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://s3napi.s3nsoftware.com/api/Quizzes/DetailedQuizz?id=${props.quizId}&UserName=${username}`,
        headers: {
          Authorization: auth.toString(),
        },
      };
      axios
        .request(config)
        .then(async (response) => {
          console.log("first responce");
          console.log(config.url);

          props.updateQuestions(response.data);
          qns = response.data;

          props.setCategory(qns[0].CatId);
          props.setSubCategory(qns[0].SubCat);

          props.updatetAnswers([]);
          props.updateDictionary({});
          props.updateAnswers("");
          // props.updateCurrentIndex(0);


          if(props.clearRetake){
            console.log("second error");
            props.updateCorrect(0);
            props.updateWrongs(0);

            props.updateTimer("00:00:00");

            props.updateCurrentIndex(0);

            let qca = [];
            for (let i = 0; i < qns.length; i++) {
              qca.push(0);
            }
            let fAnswers = qca.join("").toString();

            console.log("an", qca);
            props.updateAnswers(fAnswers);

            let ci = props.currentIndex + 0;
            PopulateQuestion(0, qns);

            // let qn = props.questions[ci];
            // props.updateCurrentQuestion(qn);
            // ////console.log(currentIndex)
            // props.updateQuizType(qn.QuizType)
            // if (qn.QuizType == 1) {
            //   const mp3 = qn.audio.replace(/sound:\/\//g, "");
            //   const url = `https://s3nsoftware.com/${mp3}`;
            //   props.updateAudioLink(url);
            // }

            props.updatetAnswers([]);
            props.updateDictionary({});
          }else{
            GetQuizProgress(qns);

          }

        })
        .catch((error) => {
          console.log("first error");
        });

      const GetQuizProgress = (qns) => {
        console.log("GetQuizProgress");

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://s3napi.s3nsoftware.com/api/UserQuizs/GetQuizzByQuizId?UserName=${username}&QuizId=${props.quizId}`,
          headers: {
            Authorization: auth.toString(),
          },
        };

        axios
          .request(config)
          .then(async (response) => {
            console.log("second responce 1", response);

            if (response.data != null && response.data.QuizId > 0) 
            {
              let localCor = 0;
              let localWron = 0;
              let localAns = [];

              for (
                let index = 0;
                index < response.data.QuizAnswers.length;
                index++
              ) {
                const element = response.data.QuizAnswers.charAt(index);
                console.log("element : ", element);
                if (element == "0") {
                  //localAns.push(index);
                  continue;
                } else if (element == "1") {
                  localCor++;
                  localAns.push(index);
                } else if (element == "2") {
                  localWron++;
                  localAns.push(index);
                }
              }


              if(props.incorrectsOnly)
              {
                let modifiedQuizAnswers = response.data.QuizAnswers.replace(/2/g, "0");

                 localCor = 0;
                 localWron = 0;
                 localAns = [];

                 for (
                  let index = 0;
                  index < modifiedQuizAnswers.length;
                  index++
                ) {
                  const element = modifiedQuizAnswers.charAt(index);
                  console.log("element : ", element);
                  if (element == "0") {
                    //localAns.push(index);
                    continue;
                  } else if (element == "1") {
                    localCor++;
                    localAns.push(index);
                  } else if (element == "2") {
                    localWron++;
                    localAns.push(index);
                  }
                }
                //props.updateIncorrectsOnly(false);
                props.updateAnswers(modifiedQuizAnswers);

              }else{
                props.updateAnswers(response.data.QuizAnswers);
              }

              

              if (localWron + localCor >= qns.length) {
                const result = await ShowOpenNextQuiz(username);

                if (result && result.length > 0) {
                  const splitted = result.split("##");
                  Swal.fire({
                    title: splitted[0], // Title from the first split value
                    text: splitted[3], // Text from the fourth split value
                    icon: "warning",
                    showCancelButton: true, // Show the Cancel button
                    confirmButtonText: "Ok",
                    cancelButtonText: "Cancel",
                    allowOutsideClick: false, // Disable closing the modal by clicking outside
                    allowEscapeKey: false, // Disable closing the modal by pressing the Esc key
                    allowEnterKey: false, // Disable the Enter key from triggering the confirm button
                    preConfirm: () => {
                      return new Promise((resolve) => {
                        resolve("ok"); // Resolve with a value indicating the OK button was clicked
                      });
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      props.setQuizzId(splitted[2]);
                      props.updateTimer("00:00:00");

                      // OK button was clicked
                      console.log("OK button clicked");
                      if (splitted[3] && splitted[3].includes("Level")) {
                        navigate("/");
                      }
                    } else if (result.isDismissed) {
                      // Cancel button was clicked or modal was dismissed
                      console.log("Cancel button clicked or modal dismissed");
                      props.setQuizzId(0);
                      props.updateQuizType(0);
                      props.setSubCategory(0);
                      navigate("/");
                    }
                  });
                } else {
                  Swal.fire({
                    title: "Quiz Completed!",
                    text: result,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                  props.setQuizzId(0);
                  props.updateQuizType(0);
                  props.setSubCategory(0);

                  navigate("/");

                  return;
                }
              } else {

                props.updateCorrect(localCor);
                props.updateWrongs(localWron);
                props.updatetAnswers(localAns);

                const str = response.data.IncAns;
                const items = str.split(";");
                let tempDictionary = {};

                if(props.incorrectsOnly)
                {
                  const filteredItems = items.filter((item) => {
                    const parts = item.split("|");
                    return parts[0] !== "2"; // Keep only items where parts[0] is NOT "2"
                  });

                  filteredItems.forEach((item) => {
                    const parts = item.split("|");
                    if (parts.length === 3) {
                      const [, , pair] = parts; // ignoring the first two parts
                      const [key, value] = item.split(","); // Use 'pair' instead of 'item' to split key-value
                      tempDictionary[key] = value;
                    }
                  });
                }else{
                  items.forEach((item) => {
                    const parts = item.split("|");
                    if (parts.length === 3) {
                      const [, , pair] = parts; // ignoring the first two parts
                      const [key, value] = item.split(",");
                      tempDictionary[key] = value;
                    }
                  });
                }

                props.updateDictionary(tempDictionary);

                if (localAns.includes(0)) {
                  console.log("nextQ");
                  PopulateNextQuestion(localAns, 0, qns);
                } else {
                  let ci = props.currentIndex + 0;
                  PopulateQuestion(0, qns);
                }
              }


              props.updateTimer(response.data.QuizTime);
            }
          })
          .catch((error) => {
            console.log("second error");
            props.updateCorrect(0);
            props.updateWrongs(0);

            props.updateTimer("00:00:00");

            props.updateCurrentIndex(0);

            let qca = [];
            for (let i = 0; i < qns.length; i++) {
              qca.push(0);
            }
            let fAnswers = qca.join("").toString();

            console.log("an", qca);
            props.updateAnswers(fAnswers);

            let ci = props.currentIndex + 0;
            PopulateQuestion(0, qns);

            // let qn = props.questions[ci];
            // props.updateCurrentQuestion(qn);
            // ////console.log(currentIndex)
            // props.updateQuizType(qn.QuizType)
            // if (qn.QuizType == 1) {
            //   const mp3 = qn.audio.replace(/sound:\/\//g, "");
            //   const url = `https://s3nsoftware.com/${mp3}`;
            //   props.updateAudioLink(url);
            // }

            props.updatetAnswers([]);
            props.updateDictionary({});

          });
      };
    }

  props.updateTimer("00:00:00");
 // props.updateCurrentIndex(1);
  //props.updateCurrentIndex(0);

    //alert(username+","+props.quizId);
    //if (props.quizId != 0) saveLastAttempted(username, props.quizId);
  }, [props.quizId]);

  // const ShowOpenNextQuiz = async (username) => {
  //   try {
  //     const response = await axios.post(
  //       "https://s3napi.s3nsoftware.com/api/QuizDatas/ShowOpenNextQuiz",
  //       null, // No data to be sent in the body
  //       {
  //         params: {
  //           Id: props.quizId,
  //           firstFind: true,
  //           username: username,
  //         },
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     return response.data.toString();
  //   } catch (error) {
  //     console.error("There was an error making the request:", error);
  //   }
  // };

  const PopulateNextQuestion = (localAns, ci, qns) => {
    if (props.corrects + props.wrongs == qns.length) {
      return;
    }

    let nextIndex = ci + 1;
    if (nextIndex > qns.length - 1) {
      nextIndex = 0;
    }
    props.updateCurrentIndex(nextIndex);

    if (localAns.includes(nextIndex)) {
      setTimeout(() => PopulateNextQuestion(localAns, nextIndex, qns), 0);
    } else {
      PopulateQuestion(nextIndex, qns);
    }
  };

  const PopulateQuestion = (ci, qns) => {
    //lblQuesNum.Text = $"Question {_CurrentQuestion}/{_MaxQuestions}";


    setCurrentText('')

    let qn = qns[ci];

    props.updateCurrentQuestion(qn);

    ////console.log(currentIndex)
    props.updateQuizType(qn.QuizType);
    if (qn.QuizType == 1 && qn.audio != null) {
      const mp3 = qn.audio.replace(/sound:\/\//g, "");
      const url = `https://s3nsoftware.com/${mp3}`;
      props.updateAudioLink(url);
    }
    // ShowHideQuizFrame();
    // animatedcorrect.IsVisible = false;
    // animatedincorrect.IsVisible = false;
  };

  const PopulatePreviousQuestion = (localAns, ci) => {
    if (props.corrects + props.wrongs == props.questions.length) {
      return;
    }

    let nextIndex = ci - 1;
    if (nextIndex == -1) {
      nextIndex = props.questions.length - 1;
    }
    props.updateCurrentIndex(nextIndex);

    if (localAns.includes(nextIndex)) {
      setTimeout(() => PopulatePreviousQuestion(localAns, nextIndex), 0);
    } else {
      let qns = props.questions;
      PopulateQuestion(nextIndex, qns);
    }
  };

  // You would also have state for managing toggles and playing audio

  const handleInputChange = (event) => {
    // checkInputValidations(event.target.value);
  };

  // const updateCurrentIndex=useCallback(()=>{setCurrentIndex(currentIndex)},[currentIndex])
  // const updateCurrentQuestion=useCallback(()=>{setCurrentQuestion(currentquestion)},[currentquestion])

  const showRandomAlert = (isCorrect) => {
    const positiveMessages = [
      "Fantastic! You nailed it!",
      "Great job! Keep it up!",
      "You're on fire! Well done!",
      "Excellent work! You're doing amazing!",
      "Spot on! Keep going!",
      "Brilliant! You got it right!",
      "Superb! That’s the right answer!",
      "Outstanding! You’re a star!",
      "Perfect! You’ve got this!",
      "Amazing! You’re unstoppable!",
      "Wonderful! Keep up the great work!",
      "Yes! You’re absolutely correct!",
      "Impressive! You really know your stuff!",
      "You’ve got it! Keep pushing forward!",
      "Awesome! That’s the way to do it!",
    ];

    const negativeMessages = [
      "Oops! Not quite right, but don’t give up!",
      "That’s okay, try again!",
      "Almost! You’re close, give it another shot!",
      "Not the right answer, but you’re learning!",
      "Incorrect, but you’re doing great!",
      "Keep trying, you’re on the right track!",
      "Don’t worry, mistakes help you learn!",
      "Not this time, but you can do it!",
      "Nice effort! Try one more time.",
      "That’s okay, you’ll get it next time!",
      "Close, but not quite. Give it another go!",
      "Learning is about trying, keep going!",
      "You missed it, but don’t lose heart!",
      "Incorrect, but keep moving forward!",
      "No worries, you’re getting closer!",
    ];

    let message = isCorrect
      ? positiveMessages[Math.floor(Math.random() * positiveMessages.length)]
      : negativeMessages[Math.floor(Math.random() * negativeMessages.length)];

    Swal.fire({
      title: message,
      //icon: isCorrect ? 'success' : 'error',
      background: "rgba(0, 0, 0, 0)", // Transparent background
      // backdrop: `
      //   rgba(0, 0, 0, 0.4)
      //   url("https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif")
      //   left top
      //   no-repeat
      // `,
      showConfirmButton: false,
      timer: 2000,
      customClass: {
        popup: "swal2-custom",
      },
      didOpen: () => {
        // Applying inline styles
        const titleElement = document.querySelector(".swal2-title");
        const textElement = document.querySelector(".swal2-html-container");

        const outlineColor = isCorrect ? "#FFD700" : "#FF4500"; // Gold for correct, OrangeRed for incorrect

        if (titleElement) {
          titleElement.style.color = "#fff"; // Dark gray color
          titleElement.style.fontWeight = "bold";
          titleElement.style.animation =
            "fadeIn 0.5s ease-in-out, bounce 1.5s infinite";
          titleElement.style.textShadow = `2px 2px 4px ${outlineColor}`; // Outline color
        }

        if (textElement) {
          textElement.style.color = "#fff"; // Dark gray color
          textElement.style.fontWeight = "bold";
          textElement.style.animation =
            "fadeIn 0.5s ease-in-out, bounce 1.5s infinite";
          textElement.style.textShadow = `2px 2px 4px ${outlineColor}`; // Outline color
        }
      },
    });
  };

  const [currentText, setCurrentText] = useState('');

  const handleGoClick = async (input) => {
    // Logic to validate the spelling
    //console.log("new value", input);
    //console.log("a value", props.currentquestion.Word.toLowerCase());
    let isCorrect = 0;

    let localCorrects = props.corrects;
    let localWrongs = props.wrongs;

    let before = "";
    let after = "";

    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    // TTS function to speak the message
    const speakMessage = (message) => {
      return new Promise((resolve) => {
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(message);

        const loadVoices = () => {
          let voices = synth.getVoices();
          const femaleVoice = voices.find(
            (voice) =>
              voice.gender === "female" ||
              voice.name.includes("Female") ||
              voice.name.includes("Zira") ||
              voice.name.includes("Google UK English Female")
          );

          if (femaleVoice) {
            utterance.voice = femaleVoice;
          }

          utterance.onend = resolve;
          synth.speak(utterance);
        };

        if (synth.getVoices().length === 0) {
          synth.addEventListener("voiceschanged", loadVoices);
        } else {
          loadVoices();
        }
      });
    };

    if (props.currentquestion.utfascii.toLowerCase() == input.toLowerCase()) {
      //console.log("matched1");
      isCorrect = 1;
      let cor = props.corrects + 1;
      props.updateCorrect(cor);
      localCorrects += 1;
      notify("Correct :-)");
      props.updateCheers(true);
      await speakMessage("Correct"); // TTS feedback for correct answer
      showRandomAlert(true); // Show negative Swal alert
    } else {
      //console.log("matched2");
      isCorrect = 2;
      let wron = props.wrongs + 1;
      localWrongs += 1;
      props.updateWrongs(wron);
      notify("Incorrect :(");
      props.updateCheers(false);
      await speakMessage("the correct answer is "); // TTS feedback for incorrect answer
      showRandomAlert(false); // Show negative Swal alert
      // TTS for each letter
      const speakLetterByLetter = (text) => {
        return new Promise((resolve) => {
          const synth = window.speechSynthesis;
          let i = 0;

          const speakLetter = () => {
            if (i < text.length) {
              let utterance = new SpeechSynthesisUtterance(text[i]);
              //console.log(text[i]);
              utterance.onend = () => {
                i++;
                setCurrentText(text.substring(0, i));
                speakLetter();
              };
              synth.speak(utterance);
            } else {
              resolve();
            }
          };

          speakLetter();
        });
      };

      const PopulateQuestionSP = () => {
        let qn = props.currentquestion;
        props.updateQuizType(qn.QuizType);
        if (qn.QuizType == 1 && qn.audio != null) {
          const mp3 = qn.audio.replace(/sound:\/\//g, "");
          const url = `https://s3nsoftware.com/${mp3}`;
          props.updateAudioLink(url);
        }
        setReplay(new Date());
        // ShowHideQuizFrame();
        // animatedcorrect.IsVisible = false;
        // animatedincorrect.IsVisible = false;
      };

      await speakLetterByLetter(props.currentquestion.utfascii);

      PopulateQuestionSP();

      await wait(3000);
      

      setCurrentText('a');

      //await popu
    }

    //console.log(props.corrects, props.wrongs);

    let dic = props.dictionary;
    //console.log(dic);
    let cq = props.currentquestion;

    let key = isCorrect + "|" + cq.Id + "|" + cq.Word;

    //addNewItemToDictionary(key, input.toLowerCase())

    before = props.answers.substring(0, props.currentIndex);
    if (props.currentIndex + 1 < props.questions.length)
      after = props.answers.substring(props.currentIndex + 1);

    let Ansers = `${before}${isCorrect}${after}`;
    props.updateAnswers(Ansers);

    props.updateDictionary((prevDictionary) => ({
      ...prevDictionary,
      [key]: input.toLowerCase(),
    }));

    const tempdict = {
      ...props.dictionary,
      [key]: input.toLowerCase(),
    };

    /////console.log("temp " + tempdict);
    // //console.log("dictionary ", props.dictionary);

    dic = props.dictionary;
    // //console.log(dic);

    let ans = props.tanswers;
    ans.push(props.currentIndex);
    props.updatetAnswers(ans);

    if (localCorrects + localWrongs == props.questions.length) {
      notify("Complete");
      updateSetAnswer(Ansers, tempdict, true);
      return;
    } else {
      updateSetAnswer(Ansers, tempdict, false);
    }

    handleNextClick();
  };

  const handleSubmitClick = (input) => {
    // Logic to validate the spelling
    //   //console.log("new value", input);
    ////console.log("a value", props.currentquestion.Word.toLowerCase());
    let isCorrect = 0;

    let localCorrects = props.corrects;
    let localWrongs = props.wrongs;

    let before = "";
    let after = "";

    let actualans = props.currentquestion.MultiChoices.split("|")[0];
    let cuans = props.currentquestion.MultiChoices.split("|")[1].split(",")[
      actualans
    ];

    if (cuans.toLowerCase() == input.toLowerCase()) {
      // //console.log("matched1");
      isCorrect = 1;
      let cor = props.corrects + 1;
      props.updateCorrect(cor);
      localCorrects += 1;
      notify("Correct :-)");
      props.updateCheers(true);
    } else {
      ////console.log("matched2");
      isCorrect = 2;
      let wron = props.wrongs + 1;
      localWrongs += 1;
      props.updateWrongs(wron);
      notify("Incorrect :(");
      props.updateCheers(false);
    }
    //console.log(props.corrects, props.wrongs);

    let dic = props.dictionary;
    //console.log(dic);
    let cq = props.currentquestion;

    let key = isCorrect + "|" + cq.Id + "|" + cuans;

    //addNewItemToDictionary(key, input.toLowerCase())

    before = props.answers.substring(0, props.currentIndex);
    if (props.currentIndex + 1 < props.questions.length)
      after = props.answers.substring(props.currentIndex + 1);

    let Ansers = `${before}${isCorrect}${after}`;
    props.updateAnswers(Ansers);

    props.updateDictionary((prevDictionary) => ({
      ...prevDictionary,
      [key]: input.toLowerCase(),
    }));

    const tempdict = {
      ...props.dictionary,
      [key]: input.toLowerCase(),
    };

    // //console.log("temp " + tempdict);
    ////console.log("dictionary ", props.dictionary);

    dic = props.dictionary;
    //console.log(dic);

    let ans = props.tanswers;
    ans.push(props.currentIndex);
    props.updatetAnswers(ans);

    if (localCorrects + localWrongs == props.questions.length) {
      notify("Complete");
      props.updateCheers(true);
      updateSetAnswer(Ansers, tempdict, true);

      //  OpenNextQuiz(props.ca);

      return;
    } else {
      updateSetAnswer(Ansers, tempdict, false);
    }

    handleNextClick();
  };

  const updateSetAnswer = (a, d, qs) => {
    props.handleSaveQuiz(a, d, qs);
  };

  const handlePrevClick = () => {
    let ci = props.currentIndex;
    const answs = props.tanswers;
    PopulatePreviousQuestion(answs, ci);
    // props.setWrong(9);
    ////console.log(currentIndex)

    //setCurrentIndex(currentIndex - 1)
    //props.setCorrect(4);
  };

  const handleNextClick = () => {
    let ci = props.currentIndex;
    const answs = props.tanswers;
    //console.log(ci, answs);
    let qns = props.questions;
    PopulateNextQuestion(answs, ci, qns);
    // props.setWrong(9);
    ////console.log(currentIndex)
  };
  

  

  // document.body.style.backgroundImage = `url(${props.imageUrl})`;
  //   document.body.style.backgroundSize = 'cover';
  //   document.body.style.backgroundPosition = 'center';
  //   document.body.style.backgroundRepeat = 'no-repeat';

  //   // Optionally, reset the background when the component unmounts
  //   return () => {
  //     document.body.style.backgroundImage = '';
  //   };
  // }, [props.imageUrl]);

  const notify = (v) => toast(v);

 

  return (
    <>
      <>
        <ToastContainer />
      </>

      {props.quiztype == 1 ? (
        <>
          <QA
            currentquestion={props.currentquestion}
            handleGoClick={handleGoClick}
            handleInputChange={handleInputChange}
            currentText={currentText}
            quiqsize={props.questions.length}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            audiolink={props.audiolink}
            currentIndex={props.currentIndex}
            quiztype={props.quiztype}
            quizId={props.quizId}
            replay={replay}
          ></QA>
        </>
      ) : (
        <></>
      )}
      {props.quiztype == 3 ? (
        <>
          <MultipleChoice
            currentquestion={props.currentquestion}
            handleSubmitClick={handleSubmitClick}
          />
        </>
      ) : (
        <></>
      )}

      

      {/** add new quiz type model */}

      {/* {(props.wrongs + props.corrects) == props.questions.length ? "complete" : "Incomplete"}
      {props.questions.map((item, index) => {
        return (<div key={index}>{item.Word}</div>)
      })} */}
      <>
        {/* <FlyingEffectComponent
          cheers={props.cheers}
          updateCheers={props.updateCheers}
        /> */}
      </>
    </>
  );
};

export default PlayGround;
