import React, { createContext, useContext, useState } from "react";

const PreferenceContext = createContext();

export const usePreference = () => {
  return useContext(PreferenceContext);
};

export const PreferenceProvider = ({ children }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const updateIsNavVisible = (value) => {
    setIsNavVisible(value);
  };

  const updateIsLogged = (value) => {
    setIsLogged(value);
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOPen] = useState(false);

  const updateIsLoginOpen = (i) => {
    setIsLoginOpen(i);
  };
  const updateIsSignUpOpen = (i) => {
    setIsSignUpOPen(i);
  };

  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const updateCommetOpen = (c) => {
    setIsCommentOpen(c);
  };

  const [comment, setComment] = useState({});

  const updateComment = (c) => {
    setComment(c);
  };

  const [isForgotPwdOpen, setIsForgotPwdOpen] = useState(false);

  const upateForgotPwdOpen = (value) => {
    setIsForgotPwdOpen(value);
  };

  const [showCart, setShowCart] = useState(false); // State to toggle cart visibility
  const [cart, setCart] = useState([]); // Cart state

  const updateCart = (value) => {
    setCart(value);
  };

  const updateShowCart = (value) => {
    setShowCart(value);
  };


  const [profilePhoto, setProfilePhoto] = useState(null);

  const updateProfilePhoto=(value)=>{
    setProfilePhoto(value)
  }

  return (
    <PreferenceContext.Provider
      value={{
        isNavVisible,
        updateIsNavVisible,
        isLogged,
        updateIsLogged,
        isLoginOpen,
        updateIsLoginOpen,
        isSignUpOpen,
        updateIsSignUpOpen,
        isCommentOpen,
        updateCommetOpen,
        comment,
        updateComment,
        isForgotPwdOpen,
        upateForgotPwdOpen,
        showCart,
        updateShowCart,
        cart,
        updateCart,
        profilePhoto, 
        updateProfilePhoto
      }}
    >
      {children}
    </PreferenceContext.Provider>
  );
};

//   export const UserPref = () => {
//     return useContext(usePreference);
//   };
