import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Checkbox, Box, Link } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { FaCopy, FaFileImport, FaMicrophone, FaStopCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import AudioPlayer from "../quiz/AudioPlayer"; // Adjust the path based on your project structure
import styled from "./TableData.css";
import Swal from "sweetalert2";

const TableDataRow = ({
  row,
  onRecordCheckboxChange,
  handleClickEditAudio,
  renderCell,
  isSelected,
  columnVisibility,
  handleSave
}) => {
  const [copiedLink2, setCopiedLink2] = useState(false);
  const [copiedLink3, setCopiedLink3] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [review1, setReview1] = useState(row.Review1); 
  const [rowData, setRowData] = useState(row); // New state to manage row data
  const audioRef = useRef(null);
  const fileInputRef = useRef(null);
  const notify = (v) => toast(v);

  useEffect(() => {
    setRowData(row); // Keep rowData in sync with row prop
  }, [row]);

  useEffect(() => {
    if (copiedLink2) {
      const timer = setTimeout(() => {
        setCopiedLink2(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copiedLink2]);

  useEffect(() => {
    if (copiedLink3) {
      const timer = setTimeout(() => {
        setCopiedLink3(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copiedLink3]);

  const handleLink1Click = (word) => {
    const url = `https://unabridged.merriam-webster.com/unabridged/${word}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLink2Click = (word) => {
    const url = `https://unabridged.merriam-webster.com/unabridged/${word}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopiedLink2(true);
        notify("Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleLink3Click = (word) => {
    const url = `https://en.wiktionary.org/wiki/${word}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopiedLink3(true);
        notify("Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleLink4Click = (word) => {
    const url = `https://en.wiktionary.org/wiki/${word}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
          setAudioChunks((prev) => [...prev, e.data]);
        };
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);

        Swal.fire({
          title: "Recording Started",
          icon: "info",
          timer: 1000,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setIsRecording(false);
      Swal.fire({
        title: "Recording Stopped",
        icon: "info",
        timer: 1000,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.load();
        audioRef.current
          .play()
          .catch((err) => console.error("Failed to play audio:", err));
      }

      const formData = new FormData();
      formData.append("audio", audioBlob);
      formData.append("word", rowData.Word);
      formData.append("wordid", rowData.Id);
      formData.append("ischecked", true);
      formData.append("username", localStorage.getItem("username"));

      axios
        .post(
          "https://s3napi.s3nsoftware.com/api/UserProfiles/UploadAaudioFile",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setRowData((prevData) => ({
              ...prevData,
              audio: response.data,
            }));
            notify("Audio uploaded successfully!");
          } else {
            notify("Upload failed!");
          }
        })
        .catch((error) => {
          console.error("Upload error:", error);
          notify("Upload error!");
        });

      setAudioChunks([]);
    }
  }, [audioChunks, rowData.Word, rowData.Id]);

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const audioBlob = new Blob([reader.result], { type: file.type });

        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
          audioRef.current.load();
          audioRef.current
            .play()
            .catch((err) => console.error("Failed to play audio:", err));
        }

        const formData = new FormData();
        formData.append("audio", audioBlob);
        formData.append("word", rowData.Word);
        formData.append("wordid", rowData.Id);
        formData.append("ischecked", true);
        formData.append("username", localStorage.getItem("username"));

        axios
          .post(
            "https://s3napi.s3nsoftware.com/api/UserProfiles/UploadAaudioFile",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setRowData((prevData) => ({
                ...prevData,
                audio: response.data,
              }));
              notify("Audio uploaded successfully!");
            } else {
              notify("Upload failed!");
            }
          })
          .catch((error) => {
            console.error("Upload error:", error);
            notify("Upload error!");
          });
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const handleReview1Click = () => {
    
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://s3napi.s3nsoftware.com/api/UserMaster/UpdateWordReviewStatus?username=${localStorage.getItem("username")}&wordId=${rowData.Id}&status=${!review1}`,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            // Update UI
            setReview1(!review1);
            notify("Review1 updated successfully!");
          } else {
            notify("Failed to update Review1!");
          }
        })
        .catch((error) => {
          notify("API error!");
        });
    
  };

  const handleCheckboxChange = (event) => {
    if (review1) {
      onRecordCheckboxChange(event, rowData.Id);
    } else {
      Swal.fire({
        title: "Review Not Completed",
        text: "Please complete the review before selecting.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleSaveField = (fieldName, newValue) => {
    let valueToSave = newValue || "";
    let updatedRowData = { ...rowData };

    const replaceAllOccurrences = (text, word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      return text.replace(regex, "____");
    };

    switch (fieldName) {
      case "defn":
        updatedRowData = {
          ...updatedRowData,
          defn: valueToSave,
          Quizdefn: replaceAllOccurrences(valueToSave, updatedRowData.Word),
        };
        break;
      case "sent":
        updatedRowData = {
          ...updatedRowData,
          sent: valueToSave,
          Quizsent: replaceAllOccurrences(valueToSave, updatedRowData.Word),
        };
        break;
      case "etym":
        updatedRowData = {
          ...updatedRowData,
          etym: valueToSave,
          Quizetym: replaceAllOccurrences(valueToSave, updatedRowData.Word),
        };
        break;
      default:
        updatedRowData = { ...updatedRowData, [fieldName]: valueToSave };
        break;
    }

    setRowData(updatedRowData);  // Update local rowData
    handleSave(updatedRowData.Id, fieldName, valueToSave);  // Update global state via parent component
  };

  const userrole = localStorage.getItem("userrole");
 

  return (
    <TableRow sx={{ height: 50 }}>
      {columnVisibility.checkbox && (
        <TableCell>
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxChange}
            inputProps={{ "aria-label": `Select row ${rowData.Id}` }}
          />
        </TableCell>
      )}
      {columnVisibility.review1 && (
        <TableCell >
        {review1 ? (
          <Check onClick={handleReview1Click} style={{ color: "green", cursor: "pointer" }} />
        ) : (
          <Close onClick={handleReview1Click} style={{ color: "red", cursor: "pointer" }} />
        )}

        {columnVisibility.pos && renderCell(rowData, "Review1Text")}

      </TableCell>
      )}
      {columnVisibility.id && <TableCell>{rowData.Id}</TableCell>}
      {columnVisibility.word &&  userrole =='SuperAdmin' ? renderCell(rowData, "Word"):  <TableCell>{rowData.Word}</TableCell> }   
      {columnVisibility.cat && renderCell(rowData, "CatId")}
      {columnVisibility.subCat && renderCell(rowData, "SubCat")}
      {columnVisibility.links && (
        <TableCell>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => handleLink4Click(rowData.Word)}
            >
              W
            </Link>
            <FaCopy
              style={{ cursor: "pointer" }}
              title={copiedLink3 ? "Copied!" : "Copy"}
              aria-label="link1"
              onClick={() => handleLink3Click(rowData.Word)}
            />
            |
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => handleLink1Click(rowData.Word)}
            >
              M
            </Link>
            <FaCopy
              style={{ cursor: "pointer" }}
              title={copiedLink2 ? "Copied!" : "Copy"}
              aria-label="link1"
              onClick={() => handleLink2Click(rowData.Word)}
            />
          </Box>
        </TableCell>
      )}
      {columnVisibility.audios && (
        <TableCell>
          {rowData.audio || audioUrl ? (
            <AudioPlayer
              audioUrl={
                audioUrl ||
                `https://s3nsoftware.com/${rowData.audio.replace(
                  /sound:\/\//g,
                  ""
                )}`
              }
              currentIndex={1}
              size={30}
              color={"#000"}
              init={false}
              quiz={false}
            />
          ) : <> <span style={{color:'red'}}>No audio</span></>}
        </TableCell>
      )}
      {columnVisibility.edit && (
        <TableCell>
          <Box display="flex" justifyContent="center" alignItems="center">
            {isRecording ? (
              <FaStopCircle
                onClick={handleStopRecording}
                style={{ color: "red" }}
                className={styled.stoprecording}
              />
            ) : (
              <FaMicrophone
                onClick={handleStartRecording}
                className={styled.recording}
              />
            )}
            OR
            <FaFileImport
              onClick={() => fileInputRef.current.click()}
              className={styled.importfile}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              accept="audio/*"
              onChange={handleFileImport}
            />
          </Box>
        </TableCell>
      )}
      {columnVisibility.pron && renderCell(rowData, "Pron")}
      {columnVisibility.pos && renderCell(rowData, "pos")}
      {columnVisibility.defnA && renderCell(rowData, "defn",handleSaveField)}
      {columnVisibility.defnT && renderCell(rowData, "Quizdefn")}
      {columnVisibility.etymA && renderCell(rowData, "etym",handleSaveField)}
      {columnVisibility.etymT && renderCell(rowData, "Quizetym")}
      {columnVisibility.sentA && renderCell(rowData, "sent",handleSaveField)}
      {columnVisibility.sentT && renderCell(rowData, "Quizsent")}
      {columnVisibility.question && renderCell(rowData, "Question")}
      {columnVisibility.answer && renderCell(rowData, "Answer")}
      {columnVisibility.choiceA && renderCell(rowData, "ChoiceA")}
      {columnVisibility.choiceB && renderCell(rowData, "ChoiceB")}
      {columnVisibility.choiceC && renderCell(rowData, "ChoiceC")}
      {columnVisibility.choiceD && renderCell(rowData, "ChoiceD")}
      {columnVisibility.choiceE && renderCell(rowData, "ChoiceE")}

      
    </TableRow>
  );
};

TableDataRow.propTypes = {
  row: PropTypes.object.isRequired,
  onRecordCheckboxChange: PropTypes.func.isRequired,
  handleClickEditAudio: PropTypes.func.isRequired,
  renderCell: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  columnVisibility: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default TableDataRow;
