import React, { useState } from "react";

const DescriptionComponent = ({ packs }) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage expanded/collapsed view
  const maxLength = 100; // Maximum characters to display when collapsed

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle between expanded and collapsed states
  };

  // Function to replace "Dolch", "Fry", and "sight words" with hyperlinks
  const replaceTextWithLinks = (text) => {
    const dolchUrl = "https://en.wikipedia.org/wiki/Dolch_word_list";
    const fryUrl = "https://sightwords.com/sight-words/fry";
    const satUrl = "https://en.wikipedia.org/wiki/Sight_word";

    // Use regex to find and replace multiple occurrences of each term
    const dolchRegex = /Dolch/g; // Matches the word "Dolch"
    const fryRegex = /Fry/g; // Matches the word "Fry"
    const satRegex = /sight words/g; // Matches the phrase "sight words"

    const replaceLinks = (text) => {
      return text
        .replace(dolchRegex, `<a href="${dolchUrl}" target="_blank">Dolch</a>`)
        .replace(fryRegex, `<a href="${fryUrl}" target="_blank">Fry</a>`)
        .replace(satRegex, `<a href="${satUrl}" target="_blank">sight words</a>`);
    };

    // Use `dangerouslySetInnerHTML` to render the links as actual HTML
    return <span dangerouslySetInnerHTML={{ __html: replaceLinks(text) }} />;
  };

  const renderDescription = () => {
    if (!packs?.Discription) {
      return "No Description Available";
    }

    if (isExpanded) {
      // Expanded view: display the full description with hyperlinks
      return packs.Discription.includes("<br>")
        ? packs.Discription.split("<br>").map((sentence, index) => (
            <span key={index}>
              {replaceTextWithLinks(sentence)}
              {index < packs.Discription.split("<br>").length - 1 && ""}
              <br />
            </span>
          ))
        : replaceTextWithLinks(packs.Discription);
    } else {
      // Collapsed view: display a part of the description with hyperlinks
      const shortDescription = packs.Discription.substring(0, maxLength);
      return (
        <>
          {packs.Discription.length > maxLength ? (
            <>
              {replaceTextWithLinks(shortDescription)}...
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={toggleExpand}
              >
                {" "}
                view More
              </span>
            </>
          ) : (
            replaceTextWithLinks(packs.Discription)
          )}
        </>
      );
    }
  };

  return (
    <div>
      <p>
        {renderDescription()}{" "}
        {packs?.Discription && packs.Discription.length > maxLength && isExpanded && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={toggleExpand}
          >
            {" "}
            less
          </span>
        )}
      </p>
    </div>
  );
};

export default DescriptionComponent;
