import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './SearchBar.module.css';
import axios from 'axios';
import debounce from 'lodash.debounce';
import Swal from 'sweetalert2';
import { AiFillInfoCircle } from 'react-icons/ai';

const SearchBar = (props) => {
  // State variables
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1); // For keyboard navigation

  // Ref for detecting clicks outside the component
  const searchBarRef = useRef();

  // Retrieve authentication tokens from localStorage
  const appToken = localStorage.getItem('webtoken');
  const auth = `Bearer ${appToken}`;

  /**
   * Debounced function to update the debouncedQuery state.
   * This prevents excessive API calls by waiting for the user to stop typing.
   */
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      setDebouncedQuery(value);
    }, 500),
    []
  );

  /**
   * Handler for input changes.
   * Updates the query state and triggers the debounced update for debouncedQuery.
   *
   * @param {Object} e - The event object from the input change.
   */
  const handleOnChange = (e) => {
    const value = e.target.value;
    console.log(`Search Input Changed: ${value}`); // For debugging purposes
    setQuery(value);
    debouncedChangeHandler(value);
  };

  /**
   * Effect to fetch search results whenever debouncedQuery changes.
   * Utilizes Axios for the API request.
   */
  useEffect(() => {
    // If the debounced query is empty or only whitespace, clear results
    if (!debouncedQuery.trim()) {
      setResults([]);
      setError(null);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://s3napi.s3nsoftware.com/api/QuizDatas/WordSearchRegular`,
          {
            params: { SearchWord: debouncedQuery },
            headers: {
              Authorization: auth,
              'Content-Type': 'application/json',
            },
          }
        );
        setResults(response.data); // Adjust based on your API response structure
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch search results. Please try again.');
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [debouncedQuery, auth]);

  /**
   * Handler for selecting a search result.
   * Updates parent component state and clears the search input and results.
   *
   * @param {number|string} id - The unique identifier of the selected result.
   */
  const handleSelection = (id,obj) => {
  //  props.updateSelectedId(id);
   // props.updateIsOpen(true);


   Swal.fire({title:`${obj.Word}`,text:`${obj.UserType}`})


    setQuery('');
    setResults([]);
    setActiveIndex(-1);
  };

  /**
   * Handler to clear the search input and results.
   */
  const handleClear = () => {
    setQuery('');
    setDebouncedQuery('');
    setResults([]);
    setError(null);
    setActiveIndex(-1);
  };

  /**
   * Effect to handle clicks outside the search bar component.
   * Closes the search results dropdown when clicking outside.
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setResults([]);
        setActiveIndex(-1);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Function to highlight the matching part of the search result.
   *
   * @param {string} word - The word to be displayed in the result.
   * @param {string} query - The current search query to match against.
   * @returns {JSX.Element|string} - The word with highlighted matching parts.
   */
  const highlightMatch = (word, query) => {
    if (!query) return word;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = word.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className={styles.highlight}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  /**
   * Handler for keyboard navigation within search results.
   *
   * @param {Object} e - The keyboard event.
   */
  const handleKeyDown = (e) => {
    if (results.length === 0) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) => (prevIndex < results.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : results.length - 1));
    } else if (e.key === 'Enter') {
      if (activeIndex >= 0 && activeIndex < results.length) {
        handleSelection(results[activeIndex].Id,results[activeIndex]);
      }
    } else if (e.key === 'Escape') {
      setResults([]);
      setActiveIndex(-1);
    }
  };

  /**
   * Effect to ensure the active search result is visible within the dropdown.
   */
  useEffect(() => {
    if (activeIndex >= 0 && activeIndex < results.length) {
      const activeItem = document.getElementById(`result-item-${activeIndex}`);
      if (activeItem) {
        activeItem.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [activeIndex, results]);

  const handleInfo=()=>{
    Swal.fire({text:'The search feature is limited to only published words.'});
  }

  return (
    <div className={styles.search_bar} ref={searchBarRef}>
      <div className={styles.input_container}>
        <div >
        <input
          className={styles.search_input} // Updated className for styling
          type="text" // Changed from "search" to "text"
          placeholder="Search a word..."
          value={query}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          aria-label="Search"
        />

        </div>
     

        {query && (
          <button
            type="button"
            onClick={handleClear}
            className={styles.clear_button}
            aria-label="Clear search input"
          >
            ×
          </button>
        )}
      </div>
      {isLoading && (
        <div className={styles.loading}>
          <div className={styles.spinner}></div> Loading...
        </div>
      )}
      {error && <div className={styles.error}>{error}</div>}
      {!isLoading && results.length > 0 && (
        <ul className={styles.search_results} role="listbox">
          {results.map((result, index) => (
            <li
              id={`result-item-${index}`}
              role="option"
             onClick={() => handleSelection(result.Id,result)}
              key={result.Id} // Use a unique key from your data
              tabIndex="-1"
              className={`${styles.search_result_item} ${
                index === activeIndex ? styles.active : ''
              }`}
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(-1)}
            >
              {highlightMatch(result.Word, query)} - {result.POS} - {result.UserType}
            </li>
          ))}
        </ul>
      )}
      {!isLoading && debouncedQuery && results.length === 0 && !error && (
        <div className={styles.no_results}>No results found.</div>
      )}

    </div>
  );
};

export default SearchBar;
