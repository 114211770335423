import React, { useEffect, useState } from "react";
import TextWithIcons from "../quiz/TextWithIcons";
import AudioPlayer from "./AudioPlayer";
import { useRef } from "react";

const QA = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputRef = useRef(null); // Create a ref for the input field

  const getSpecialCharacters = (inputString) => {
    // Use a regular expression to match special characters
    const specialCharacterRegex = /[^a-zA-Z0-9\s]/g;

    // Use the match() method to get an array of matched characters
    const specialCharactersArray = inputString.match(specialCharacterRegex);

    // Check if there are any special characters
    if (specialCharactersArray) {
      // Join the array into a string of special characters
      const specialCharactersString = specialCharactersArray.join("");
      return specialCharactersString;
    } else {
      return "";
    }
  };

  const checkInputValidations = (spelling) => {
    // Getting the actual word
    const actual_word = props.currentquestion.utfascii;
    // Getting only special characters in the original word
    const result = getSpecialCharacters(actual_word);

    // Process the last character typed
    const fValue = spelling.slice(-1);

    // If character not present in result
    if (!result.includes(fValue)) {
      // Concatenate only allowed characters
      return spelling.slice(0, -1) + fValue.replace(/[^A-Za-z]/g, "");
    }
    return spelling;
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    const validatedValue = checkInputValidations(newValue);
    setInputValue(validatedValue);
    // const filteredValue = newValue.replace(/[^a-zA-Z]/g, ''); // Remove non-alphabetic characters
    // setInputValue(filteredValue);
    // if (!event.target.value.match(/^[a-zA-Z]*$/)) {
    //   setError('Only letters are allowed');
    // } else {
    //   setError('');
    // }
  };

  // Function to set focus dynamically
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input field
    }
  };

  const [isPosShow, setPosShow] = useState(false);
  const [isDefnShow, setDefnShow] = useState(false);
  const [isEtymShow, setEtymShow] = useState(false);
  const [isSentShow, setSentShow] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false); // State to control enable/disable

  // Set focus automatically when the component mounts (optional)
  useEffect(() => {
    focusInput(); // Focus input when the component mounts
    setInputValue('');

    setPosShow(false);
    setEtymShow(false);
    setDefnShow(false);
    setSentShow(false);
    setIsDisabled(false);
    focusInput();
  }, [props.currentIndex]);

  useEffect(() => {
    setInputValue(props.currentText);
  }, [props.currentText]);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submit action

    if (!inputValue.trim()) {
      setError("Input cannot be empty");
      return;
    }
    //console.log('Submitted value:', inputValue);
    // Add your submit logic here (e.g., sending data to a server).
    //suresh
    setIsDisabled(true); // Disable the input and button after submit
    props.handleGoClick(inputValue);
    setInputValue('');
  };

  const showall =
    "Part of speech: " +
    props.currentquestion.pos +
    "<br/>" +
    "Definition: " +
    props.currentquestion.Quizdefn +
    "<br/>" +
    "Etymology: " +
    props.currentquestion.Quizetym +
    "<br/>" +
    "Sentence: " +
    props.currentquestion.Quizsent;

  const showalla = [
    `Part of speech,  ${props.currentquestion.pos},,,`,
    `,Definition,  ${props.currentquestion.defn},,,`,
    `,Etymology,${props.currentquestion.etym},,,`,
    `,Sentence,  ${props.currentquestion.sent},,,`,
  ];

  const updateType = (value) => {
    if (value == "0") {
      setPosShow(true);
      setEtymShow(true);
      setDefnShow(true);
      setSentShow(true);
    } else if (value == "1") {
      setPosShow(true);
    } else if (value == "2") {
      setDefnShow(true);
    } else if (value == "3") {
      setEtymShow(true);
    } else if (value == "4") {
      setSentShow(true);
    }
  };

  const playerBgStyle = {
    backgroundImage: 'url("../../assets/img/aab.png")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
    marginTop: '20px',
    marginBottom: '5px',
  };

  const controlsStyle = {
    width: '80%',
    margin: 'auto'
  };

  const answerInputsStyle = {
    marginBottom: '30px',
    justifyContent: 'center',
    width: '100%'
  };

 

  const inputAnswerStyle = {
    borderRadius: '26px',

    color: "white", // Color of the input text
    backgroundColor: "transparent", // Example background color, adjust as needed
    border: "1px solid #ccc", // Example border, adjust as needed
    fontSize: "16px",
    padding: "8px",
    outline: "none",
    width: "100%",
    "::placeholder": {
      color: "white", // Placeholder color set to white
    },
  };

  return (
    <div>
      <div className="shadow" style={playerBgStyle}>
        <div className="row" style={controlsStyle}>
          <div className="col-sm-12 col-md-6 align-self-center">

            <div className="d-flex flex-column align-items-center align-content-center"
              id="player-controls"
            >
              <div className="d-flex">
                <div>
                  <i
                    className={`icon ion-chevron-left small-icon ${
                      isDisabled ? "disabled" : ""
                    }`}
                    data-bss-hover-animate="bounce"
                    onClick={!isDisabled ? props.handlePrevClick : null} // Disable click
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                  ></i>
                </div>

                {props.quiztype === 1 && (
                  <AudioPlayer
                    audioUrl={props.audiolink}
                    currentIndex={props.currentIndex}
                    color={"#fff"}
                    init={true}
                    quiz={true}
                    replay={props.replay}
                    isDisabled={isDisabled} // Pass disabled state to the AudioPlayer
                  />
                )}

                <div>
                  <i
                    className={`icon ion-chevron-right small-icon ${
                      isDisabled ? "disabled" : ""
                    }`}
                    data-bss-hover-animate="bounce"
                    onClick={!isDisabled ? props.handleNextClick : null} // Disable click
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                  ></i>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <p style={{ marginBottom: 0, marginTop: "15px" }}>
                    {props.currentIndex + 1}/{props.quiqsize}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <form onSubmit={handleSubmit} className="d-flex" style={answerInputsStyle}>
              <div className="d-flex" style={answerInputsStyle}>
                <input style={inputAnswerStyle}
                  type="text"
                  spellCheck="false"
                  onContextMenu={(e) => e.preventDefault()}
                  // onSelect={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onDrag={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  inputMode="none"

                  value={inputValue.toLocaleLowerCase()}
                  onChange={handleChange}
                  placeholder="Enter Spelling"
                  ref={inputRef} // Attach the ref to the input element
                  disabled={isDisabled} // Control disabled state
                />
                <i
                  className="far fa-arrow-alt-circle-right submit-answer-icon"
                  data-bss-hover-animate="pulse"
                  onClick={!isDisabled ? handleSubmit : null} // Disable click if the input is disabled
                ></i>
              </div>
            </form>
          </div>
          <style jsx>{`
    input::placeholder {
      color: white;
    }
  `}</style>
        </div>

        <div className="hints-row">
          <div>
            <div className="row" style={{ marginBottom: "5px" }}>
              <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={"All Hints"}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={true}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={true}
                    />
                  </>
                </p>
              </div>
              {/* <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={""}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={false}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={false}
                    />
                  </>
                </p>
              </div>

              <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={""}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={true}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={true}

                    />
                  </>
                </p>
              </div> */}
            </div>

            <div className="row">
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Part of Speech</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          {
                            <TextWithIcons
                              text={"Part of speech"}
                              value={props.currentquestion.pos}
                              audio={props.currentquestion.pos}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={1}
                              updateType={updateType}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isPosShow ? props.currentquestion.pos : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Definition</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <div>
                            <TextWithIcons
                              text={"Definition"}
                              value={props.currentquestion.Quizdefn}
                              audio={props.currentquestion.defn}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={2}
                              updateType={updateType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isDefnShow ? props.currentquestion.Quizdefn : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "6px" }}>
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Etymology</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <div>
                            <TextWithIcons
                              text={"Etymology"}
                              value={props.currentquestion.Quizetym}
                              audio={props.currentquestion.etym}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={3}
                              updateType={updateType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isEtymShow ? props.currentquestion.Quizetym : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Sentence</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <TextWithIcons
                            text={"Sentence"}
                            value={props.currentquestion.Quizsent}
                            audio={props.currentquestion.sent}
                            audioenable={true}
                            textenable={true}
                            textshow={false}
                            type={4}
                            updateType={updateType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isSentShow ? props.currentquestion.Quizsent : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default QA;
