import React, { useState, useRef, useEffect } from "react";
import AuthService from "../../services/auth.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";
import { usePreference } from "../../context/UserContext";
import { Encrypt } from "../../common/EncryptionDecryption";
import styles from "../../common/WordModel.module.css";

// Validation functions
const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">This field is required!</div>
    );
  }
};

const validOtp = (value) => {
  if (value.length !== 6) {
    return (
      <div className="invalid-feedback d-block">OTP must be 6 digits long!</div>
    );
  }
};

const ForgotPasswordModal = ({ IsForgotPwd, upateForgotPwd }) => {
  
  const closeModal = () => {
    upateForgotPwd(false);
    setUsername("");
    setOtp("")
    setPassword("")
    setConfirmPassword("")
    setLoading(false);
    setMessage("")
    setOtpSent(false);
    setOtpValidated(false);
    setSuccess(false)
  };

  const form = useRef();
  const checkBtn = useRef();

  const { updateIsLogged, updateIsLoginOpen,upateForgotPwdOpen } = usePreference();

  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [timer, setTimer] = useState(600);
  const [success, setSuccess] = useState(false); // New state to track success

  // Check if both fields are filled to enable the OTP button
  const isOtpButtonEnabled = !!username;

  // Handlers for input changes
  const onChangeUsername = (e) => setUsername(e.target.value);
  const onChangeOtp = (e) => setOtp(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);
  const onChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);

  // Timer function (10-minute countdown)
  useEffect(() => {
    if (otpSent && timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [otpSent, timer]);

  // Format timer to mm:ss
  const formatTimer = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const [initotp, setInitOtp] = useState('000000');

  // Reset function to allow the user to resend OTP after timer expires
  const resetOtpProcess = () => {
    setOtp("");
    setOtpSent(false);
    setOtpValidated(false);
    setTimer(60);
    setMessage("");
  };

  // Handle OTP request
  const handleGetOtp = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    const newOtp = Math.floor(100000 + Math.random() * 900000).toString();
    setInitOtp(newOtp);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.requestOtp(username, newOtp)
        .then((response) => {
          if (response.status === 200) {
            if (response.data && response.data.MaskedEmail) {
              setLoading(false);
              setMessage(`OTP sent successfully! to : ${response.data.MaskedEmail}`);
              setOtpSent(true);
              setTimer(60);
            } else {
              setLoading(false);
              setMessage("Invalid action, please retry with correct username");
            }
          } else {
            setLoading(false);
            setMessage("Invalid action, please retry with correct username");
          }
        })
        .catch((error) => {
          setLoading(false);
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
        });
    } else {
      setLoading(false);
    }
  };

  // Handle OTP validation
  const handleValidateOtp = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    if (otp === initotp) {
      setLoading(false);
      setMessage("OTP validated!");
      setOtpValidated(true);
    } else {
      setLoading(false);
      setMessage("OTP not matched!");
    }
  };

  // Handle password reset submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    form.current.validateAll();

    // Manual check if passwords match
    if (password !== confirmPassword) {
      setLoading(false);
      setMessage("Passwords do not match!");
      return;
    }

    if (checkBtn.current.context._errors.length === 0) {
      const encryptedPassword = Encrypt(password);
      console.log("encryptedPassword : "+encryptedPassword)

      AuthService.resetPassword(username, encryptedPassword)
        .then((response) => {
          setLoading(false);
          setSuccess(true); // Mark the process as successful
          setMessage("Password reset successful!");
          updateIsLogged(true);
          updateIsLoginOpen(false);
        })
        .catch((error) => {
          setLoading(false);
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modal_container}>
      {IsForgotPwd && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              <label
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                Reset Password
              </label>

              {!success ? (
                // Show the form only if the password reset is not successful
                <div className="container mt-3">
                  <div className="col-md-12">
                    <div className="card card-container">
                      <Form ref={form}>
                        {/* Username and OTP fields */}
                        {!otpSent && (
                          <>
                            <div className="form-group">
                              <label htmlFor="username">Username</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                onChange={onChangeUsername}
                                validations={[required]}
                              />
                            </div>

                            <div className="form-group">
                              <button
                                className="btn btn-primary btn-block"
                                disabled={!isOtpButtonEnabled || loading}
                                onClick={handleGetOtp}
                              >
                                {loading && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Get OTP</span>
                              </button>
                            </div>
                          </>
                        )}

                        {/* OTP validation fields */}
                        {otpSent && !otpValidated && (
                          <>
                            <div className="form-group">
                              <label htmlFor="otp">Enter OTP (valid for {formatTimer(timer)})</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="otp"
                                value={otp}
                                onChange={onChangeOtp}
                                validations={[required, validOtp]}
                              />
                            </div>

                            <div className="form-group">
                              <button
                                className="btn btn-primary btn-block"
                                disabled={loading || timer <= 0}
                                onClick={handleValidateOtp}
                              >
                                {loading && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Validate OTP</span>
                              </button>
                            </div>

                            {timer <= 0 && (
                              <>
                                <div className="alert alert-danger" role="alert">
                                  OTP has expired. Please request a new OTP.
                                </div>
                                <button
                                  className="btn btn-warning btn-block"
                                  onClick={resetOtpProcess}
                                >
                                  Resend OTP
                                </button>
                              </>
                            )}
                          </>
                        )}

                        {/* Password fields */}
                        {otpValidated && (
                          <>
                            <div className="form-group">
                              <label htmlFor="password">New Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={onChangePassword}
                                validations={[required]}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="confirmPassword">Confirm Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={onChangeConfirmPassword}
                                validations={[required]}
                              />
                            </div>

                            <div className="form-group">
                              <button
                                className="btn btn-primary btn-block"
                                disabled={loading}
                                onClick={handleSubmit}
                              >
                                {loading && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Submit</span>
                              </button>
                            </div>
                          </>
                        )}

                        {/* Error or success message */}
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}

                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                      </Form>
                    </div>
                  </div>
                </div>
              ) : (
                // Show success animation after password reset
                <div className={styles.successAnimation}>
                  <div className={styles.successMessage}>
                    <i className="fas fa-check-circle"></i> {/* Success icon */}
                    <h2>Password reset successful!</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordModal;
