import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import Swal from "sweetalert2";
import { Form, Button, Card, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { usePreference } from "../../context/UserContext";
import { Link } from "react-router-dom";
import styles from "../../common/WordModel.module.css";
import styles1 from "./Signup.module.css";
import { Encrypt } from "../../common/EncryptionDecryption";

const SignUpModel = (props) => {
  const closeModal = () => {
    props.updateIsOpen(false);
  };

  const {
    updateIsLoginOpen,
    updateIsSignUpOpen,
  } = usePreference();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    emailAddress: "",
    mobileNumber: "",
    promoCode: "",
    receiveSMS: false,
    acceptTerms: false,
  });

  const [sButtomVisible, setSButtonVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [usernameStatus, setUsernameStatus] = useState("");

  const validateMobileNumber = (number) => {
    return isValidPhoneNumber(number);
  };

  const handleChangeMnumber = (e) => {
    setFormData({
      ...formData,
      mobileNumber: e,
    });
  };

  // Validate username and password onChange
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    const errors = { ...formErrors }; // Create a copy of formErrors to update

    if (name === "username") {
      if (value.length < 3 || !/^[a-zA-Z0-9]+$/.test(value)) {
        errors.username =
          "Username must be at least 3 characters long and contain only alphanumeric characters.";
        setSButtonVisible(false);
      } else {
        errors.username = "";
        checkUsernameExists(value); // Check if username exists
      }
    }

    if (name === "password") {
      if (value.length < 6 || !/[0-9]/.test(value) || !/[!@#$%^&*]/.test(value)) {
        errors.password =
          "Password must be at least 6 characters long and contain at least one number and one special character.";
      } else {
        errors.password = "";
      }
    }

    if (name === "confirmPassword") {
      if (value !== formData.password) {
        errors.confirmPassword = "Passwords do not match.";
      } else {
        errors.confirmPassword = "";
      }
    }

    setFormErrors(errors); // Update form errors
  };

  // Check if username exists (for demonstration purposes)
  const checkUsernameExists = async (username) => {
    let returnValue = "";
    try {
      const response = await fetch(
        `https://s3napi.s3nsoftware.com/api/UserMasters/UserIsExisted?username=${username}`
      );
      const data = await response.json();

      if (data === 1) {
        returnValue = "Username not available";
        setUsernameStatus("unavailable");
        setSButtonVisible(false);
      } else {
        returnValue = "Username available";
        setUsernameStatus("available");
        setSButtonVisible(true);
      }
    } catch (error) {
      returnValue = "Error checking username";
      setUsernameStatus("error");
    }
    return returnValue;
  };

  const validate = (values) => {
    const errors = {};

    // Username validation
    if (!values.username) {
      errors.username = "Username is required.";
    } else if (values.username.length < 3 || !/^[a-zA-Z0-9]+$/.test(values.username)) {
      errors.username = "Username must be at least 3 characters long and alphanumeric.";
    }

    // Password validation
    if (!values.password) {
      errors.password = "Password is required.";
    } else if (
      values.password.length < 6 ||
      !/[0-9]/.test(values.password) ||
      !/[!@#$%^&*]/.test(values.password)
    ) {
      errors.password = "Password must be at least 6 characters long, with a number and a special character.";
    }

    // Confirm Password validation
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    // Email validation
    if (!/\S+@\S+\.\S+/.test(values.emailAddress)) {
      errors.emailAddress = "Email is invalid.";
    }

    // Accept Terms validation
    if (!values.acceptTerms) {
      errors.acceptTerms = "You must agree to the terms and conditions.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const errors = validate(formData);

    if (Object.keys(errors).length === 0) {
      // Proceed with the form submission

      let _password=Encrypt(formData.password);


      const response = await fetch(
        "https://s3napi.s3nsoftware.com/api/UserMasters/SaveUserInformation",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            MobileNumber: formData.mobileNumber,
            IsCheckSMS: formData.receiveSMS,
            UserMaster: {
              UserName: formData.username,
              UserPassword: _password,
              UserRoles: formData.promoCode || "User",
              UserEmailId: formData.emailAddress,
              Verified: false,
              ActivationCode: generateRandomCode(20),
            },
          }),
        }
      );

      if (!response.ok) {
        Swal.fire({
          title: "Registration failed!",
          text: "Please check all input fields.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Registration completed!",
          text: "Please check Email or SMS to verify your account.",
          icon: "success",
          confirmButtonText: "Ok",
        });
        updateIsSignUpOpen(false);
        updateIsLoginOpen(true);
      }
    } else {
      setFormErrors(errors);
      Swal.fire({
        title: "Registration failed!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  const generateRandomCode = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };



  const updateOpenLoginModel=()=>{
    updateIsLoginOpen(true);
    updateIsSignUpOpen(false);
  }
  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
         
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>

            <label
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "25px", // Added "px" to fontSize
                }}
              >
                SignUp
              </label>
            <div>
              <Card className={styles1.cardSignup}>
                <Card.Body>
                  {/* <Card.Title className={styles1.cardTitle}>Sign Up</Card.Title> */}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formUsername" className={styles1.formGroup}>
                      <Form.Control
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="Enter Username"
                        isInvalid={!!formErrors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.username}
                      </Form.Control.Feedback>
                      {usernameStatus && (
                        <div style={{ color: usernameStatus === "unavailable" ? "red" : "green" }}>
                          {usernameStatus}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formEmailAddress" className={styles1.formGroup}>
                      <Form.Control
                        type="email"
                        name="emailAddress"
                        placeholder="Email"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        isInvalid={!!formErrors.emailAddress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.emailAddress}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPassword" className={styles1.formGroup}>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={!!formErrors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formConfirmPassword" className={styles1.formGroup}>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!formErrors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>

                   <Form.Group controlId="formMobileNumber" className={styles1.formGroup}>
  <PhoneInput
    international
    defaultCountry="US"
    value={formData.mobileNumber}
    name="mobileNumber"
    placeholder="Enter your mobile number"
    onChange={handleChangeMnumber}
    inputClass={styles1.phoneInput} // Custom styles
    className="form-control"
    disableCountryGuess
    countryCallingCodeEditable={false} // Disable editing the country code
  />
  {formErrors.mobileNumber && (
    <Alert variant="danger" className={styles1.alertDanger}>
      {formErrors.mobileNumber}
    </Alert>
  )}
</Form.Group>



                    <Form.Group controlId="formAcceptTerms" className={styles1.formGroup}>
                      <Form.Check
                        type="checkbox"
                        label="I agree to the Terms and Conditions"
                        name="acceptTerms"
                        checked={formData.acceptTerms}
                        onChange={handleChange}
                        isInvalid={!!formErrors.acceptTerms}
                        feedback={formErrors.acceptTerms}
                      />
                    </Form.Group>

                    {sButtomVisible ? (
                      <Button type="submit" className={`${styles1.btnPrimary} w-100 mt-3`}>
                        Sign Up
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Form>

                  <div className="text-center mt-3">
                    Already have an account?{" "}
                    <span style={{ cursor: "pointer", color: "#007bff" }}> 
                      <div>
                        <Link style={{textDecoration:"none"}} onClick={ updateOpenLoginModel}>Login</Link>
                      </div>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpModel;
