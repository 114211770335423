import React, { useState } from 'react';

const Extra = () => {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  // Handle submenu open on hover
  const handleMouseEnter = () => {
    setSubmenuOpen(true);
  };

  // Handle submenu close on mouse leave
  const handleMouseLeave = () => {
    setSubmenuOpen(false);
  };

  // Handle selection and close submenu
  const handleItemClick = (item) => {
    alert(`You selected: ${item}`);
    setSubmenuOpen(false); // Close the submenu after selection
  };

  // Inline styles for the component
  const menuStyle = {
    position: 'relative',
    display: 'inline-block',
  };

  const buttonStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
  };

  const submenuStyle = {
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    listStyle: 'none',
    margin: '0',
    padding: '0',
    width: '150px',
    zIndex: '1000',
  };

  const submenuItemStyle = {
    padding: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  };

  const submenuItemHoverStyle = {
    backgroundColor: '#f0f0f0',
  };

  return (
    <div 
      style={menuStyle} 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      <button style={buttonStyle}>Main Menu</button>
      {submenuOpen && (
        <ul style={submenuStyle}>
          <li 
            style={submenuItemStyle} 
            onClick={() => handleItemClick('Option 1')}
            onMouseOver={(e) => e.currentTarget.style = submenuItemHoverStyle}
            onMouseOut={(e) => e.currentTarget.style = submenuItemStyle}
            tabIndex="0"
          >
            Option 1
          </li>
          <li 
            style={submenuItemStyle} 
            onClick={() => handleItemClick('Option 2')}
            onMouseOver={(e) => e.currentTarget.style = submenuItemHoverStyle}
            onMouseOut={(e) => e.currentTarget.style = submenuItemStyle}
            tabIndex="0"
          >
            Option 2
          </li>
          <li 
            style={submenuItemStyle} 
            onClick={() => handleItemClick('Option 3')}
            onMouseOver={(e) => e.currentTarget.style = submenuItemHoverStyle}
            onMouseOut={(e) => e.currentTarget.style = submenuItemStyle}
            tabIndex="0"
          >
            Option 3
          </li>
        </ul>
      )}
    </div>
  );
};

export default Extra;
