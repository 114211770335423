import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("12345678123456781234567812345678"); // 32 bytes for AES-256
const iv = CryptoJS.enc.Utf8.parse("1234567812345678"); // 16 bytes for AES block size

export const Encrypt = (plainText) => {
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(plainText),
    key,
    {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // Convert the encrypted result to Base64 for safe transmission/storage
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

export const Decrypt = (cipherText) => {
  // Convert the Base64 encoded string back to the original encrypted format
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cipherText),
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    keySize: 256 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return CryptoJS.enc.Utf8.stringify(decrypted);
};

const EncryptionDecryption = {
  Decrypt,
  Encrypt,
};
export default EncryptionDecryption;
