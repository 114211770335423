import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const EditableTextField = ({ value, onSave, label, type = 'text' }) => {
    const [editValue, setEditValue] = useState(value);

    const handleTextChange = (event) => {
        setEditValue(event.target.value);
    };

    const handleBlur = () => {
        onSave(editValue); // Trigger save when the user exits the field
    };

    const handleSaveClick = () => {
        onSave(editValue); // Also allow saving with a save icon
    };

    return (
        <TextField
            type={type}
            label={label}
            value={editValue}
            onChange={handleTextChange}
           // onBlur={handleBlur}
            fullWidth
            InputProps={{
                endAdornment: (
                    <IconButton onClick={handleSaveClick}>
                        <SaveIcon />
                    </IconButton>
                ),
            }}
        />
    );
};

export default EditableTextField;
