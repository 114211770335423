import React, { useEffect, useState } from 'react';
import styles from '../../components/quiz/MultipleChoice.module.css'
import Swal from 'sweetalert2';

const MultipleChoice = (props) => {
    const [options, setOptions] = useState([])

    const [selectedOption, setSelectedOption] = useState(null);
    const [hasAnswered, setHasAnswered] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState("")

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setHasAnswered(true);
        setTimeout(() => {
            props.handleSubmitClick(option)
            setHasAnswered(false);
        }, 2000)

    };

    useEffect(() => {
        if (!props.currentquestion || !props.currentquestion.MultiChoices) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Data',
                text: 'Invalid question data',
            });
            return;
        }
    
        const multiChoicesParts = props.currentquestion.MultiChoices.split('|');
        if (multiChoicesParts.length < 2) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Format',
                text: 'Invalid question format',
            });
            return;
        }
    
        const optind = parseInt(multiChoicesParts[0], 10);
        const opt = multiChoicesParts[1].split(',');
    
        if (isNaN(optind) || optind < 0 || optind >= opt.length) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Index',
                text: 'Invalid answer index',
            });
            return;
        }
    
        const ca = opt[optind];
        setOptions(opt);
        setCorrectAnswer(ca);
    }, [props.currentquestion]);



    //   const handleRadioChange = (event) => {
    //     setSelectedOption(event.target.value);
    //   };

    //   const resetRadioButtons = () => {
    //     if(selectedOption==''&& selectedOption=="")
    //     return
    //     props.handleSubmitClick(selectedOption);
    //     setSelectedOption("");
    //   };

    return (
        <div className={styles.question_container}>
            <div className={styles.options}>
                {options.map((option, index) => {
                    // Array of option letters
                    const optionLetters = ['A', 'B', 'C', 'D', 'E']; // Extend this array if there are more than 5 options
                    return (
                        <div
                            key={index}
                            className={`${styles.option} ${hasAnswered
                                ? option === correctAnswer
                                    ? styles.correct
                                    : selectedOption === option
                                        ? styles.incorrect
                                        : ''
                                : ''
                                }`}
                            onClick={() => !hasAnswered && handleOptionClick(option)}
                        >
                            {`${optionLetters[index]}. ${option}`} {/* Prepend the letter before the option */}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MultipleChoice;
