import styles from "../../common/WordModel.module.css";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";
import { usePreference } from "../../context/UserContext";
import { Encrypt } from "../../common/EncryptionDecryption";

// Required validation function
const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">This field is required!</div>
    );
  }
};

const LoginModel = (props) => {
  const closeModal = () => {
    props.updateIsOpen(false);
  };
  const form = useRef();
  const checkBtn = useRef();

  const {
    updateIsLogged,
    updateIsLoginOpen,
    updateIsSignUpOpen,
    upateForgotPwdOpen,
  } = usePreference();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // Handle login
  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      localStorage.clear();
      AuthService.userexisted(username).then(
        (response) => {
          if (response === 1) {
            let _password = Encrypt(password);

            AuthService.generateToken(username, _password).then(
              (response1) => {
                const lgd = localStorage.getItem("loggedin");
                if (lgd==null || lgd === "false") {
                  setMessage("Invalid credentials!");
                  setLoading(false);
                  return;
                }

                setMessage("Login success!");
                updateIsLogged(true);
                updateIsLoginOpen(false);

                AuthService.getUserRole(username).then((response) => {
                  if (response.UserRole === "No User") {
                    alert("No User");
                    return;
                  }
                  window.location.reload();
                });
              },
              (error) => {
                const resMessage =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                setLoading(false);
                setMessage(resMessage);
              }
            );
          } else if (response === 0) {
            setLoading(false);
            setMessage("Username doesn't exist.");
          } else if (response === 2) {
            setLoading(false);
            setMessage(
              "Account not verified. Please check your email and click on the link provided to activate the account."
            );
          }
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    updateIsLoginOpen(false);
    updateIsSignUpOpen(true);
  };

  const handleForgot = () => {
    updateIsLoginOpen(false);
    upateForgotPwdOpen(true);
    updateIsSignUpOpen(false);
  };

  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              <label
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "25px", // Added "px" to fontSize
                }}
              >
                Login
              </label>
              <div className="container mt-3">
                <div className="col-md-12">
                  <div className="card card-container">
                    {/* <img
                      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      alt="profile-img"
                      className="profile-img-card"
                    /> */}

                    <Form onSubmit={handleLogin} ref={form}>
                      {/* Username Input */}
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="username"
                          value={username}
                          onChange={onChangeUsername}
                          validations={[required]}
                        />
                      </div>

                      {/* Password Input */}
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onChangePassword}
                          validations={[required]}
                        />
                      </div>

                      {/* Login Button */}
                      <div className="form-group">
                        <button className="btn btn-secondary btn-block" disabled={loading}>
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Login</span>
                        </button>
                      </div>

                      {/* Forgot Password and Signup Links */}
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ marginRight: "20px" }}>
                          <Link style={{ textDecoration:"none" }} onClick={handleForgot}>Forgot Password</Link>
                        </div>
                        <div>
                          <Link  style={{ textDecoration:"none" }} onClick={handleSignUp}>Signup</Link>
                        </div>
                      </div>

                      {/* Error Message */}
                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginModel;
